import React, { useContext } from "react";
import "./homeappbar.scss";
import logo from "../../images/logo.png";
import { Button } from "@mui/material";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import { DarkModeContext } from "../../context/darkModeContext";
import AppRegistrationOutlinedIcon from "@mui/icons-material/AppRegistrationOutlined";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import { Link } from "react-router-dom";
export const HomeAppBar = () => {
  const { toggle, darkMode } = useContext(DarkModeContext);
  return (
    <>
      <div className="homenav">
        <div className="leftside">
          <img src={logo} alt="soarlogo" />
          <Link to="/">SOARXBITS</Link>
        </div>
        <div className="rightside">
          {darkMode ? (
            <WbSunnyOutlinedIcon onClick={toggle} />
          ) : (
            <DarkModeOutlinedIcon onClick={toggle} />
          )}
          <Button
            href="/login"
            size="small"
            variant="outlined"
            className="btnlogin"
            startIcon={<LoginOutlinedIcon />}
          >
            Log In
          </Button>
          <Button
            href="/signup"
            size="small"
            variant="contained"
            className="btnsignup"
            startIcon={<AppRegistrationOutlinedIcon />}
          >
            Sign Up
          </Button>
        </div>
      </div>
    </>
  );
};
