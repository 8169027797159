import React, { useState } from "react";
import "./indsignupemail.scss";
import { Box, Button, Card, CardContent, Divider, Grid } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { FormInput } from "../../../components/forminput/FormInput";
import CircularProgress from '@mui/material/CircularProgress';
import signup from "../../../images/signup.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
export const IndSignupEmail = () => {
  const Navigate = useNavigate();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apiurl = process.env.REACT_APP_API_ADDR;
  const validationSchema = Yup.object({
    otp: Yup.number("Invalid OTP, Please Check!!!").required(
      "Field can not be empty."
    ),
    password: Yup.string()
      .min(8, "Minimum 8 Charecter Required")
      .matches(/[a-zA-Z]/, "Password can only contain Latin letters.")
      .required("Invalid Password."),
    confpassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Password not matched"
    ),
  });
  const formik = useFormik({
    initialValues: {
      data: "",
      otp: "",
      password: "",
      confpassword: "",
      referral:''
    },
    validationSchema,
    onSubmit: async (values) => {
      disableButton(true);
      const email = localStorage.getItem("soarxemail");
      values.data = email;
      const { data, otp, password, referral } = values;
      axios
        .post(url + "/indmailsignupcomplete", {
          method: "Email",
          data,
          otp,
          password,
          referral
        })
        .then(function (response) {
          console.log(response.status);
          if (response.status == 200) {
            Navigate("/displaymsg/Success/Account Created Successfully. Please Proceed to Login/login/1");
          }
        })
        .catch(function (error) {
          notify("Something went wrong! Try after sometimes.");
          disableButton(false);
        });
    },
  });
  const disableButton = (x) => {
    setButtonDisabled(x);
  };
  const notify = (msg) => toast(msg);
  return (
    <>
    <ToastContainer />
      <Grid container spacing={2} className="indsignupemail">
        <Grid item xs={12} md={6} className="leftside">
          <Card sx={{backgroundColor:'transparent'}}>
            <CardContent>
            <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit} autoComplete="off">
            <div className="goback">
            <KeyboardDoubleArrowLeftIcon />
            <Link to="/indsignup" className="link">go back</Link>
          </div>
          <Divider />
          <h2>Email has sent to your Registered Mail:</h2>
              <div className="form-group">
                <div>Email OTP [Valid for 15 Minutes]</div>
                <FormInput
                  type="text"
                  name="otp"
                  label="Enter Email OTP"
                  autoFocus
                  onChange={formik.handleChange}
                  value={formik.values.otp}
                />
                <FormInput
                  type="password"
                  name="password"
                  label="Enter Password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                <FormInput
                  type="password"
                  name="confpassword"
                  label="Confirm Password"
                  onChange={formik.handleChange}
                  value={formik.values.confpassword}
                />
                <FormInput
                  type="text"
                  name="referral"
                  label="Referral Id"
                  onChange={formik.handleChange}
                  value={formik.values.referral}
                />
              </div>
              <p>
                By creating an account, I agree to soarxbits's{" "}
                <Link to="#" className="link">Terms of Service</Link> and{" "}
                <Link to="#" className="link">Privacy Policy</Link>.
              </p>
              {isButtonDisabled === false ? (
                <Button type="submit" variant="outlined" size="large" >
                  Submit
                </Button>
              ) : (
                <Button type="button" variant="outlined" startIcon={<CircularProgress />} size="large">
                  Submiting..
                </Button>
              )}
              
            </Form>
          </FormikProvider>
            </CardContent>
          </Card>
          
          
        </Grid>
        <Grid item xs={12} md={6} className="rightside">
          <Box sx={{width:'100%'}}>
            <img src={signup} alt="" />
          </Box>
          
        </Grid>
      </Grid>
    </>
  );
};
