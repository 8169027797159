import React from "react";
import "./userfooter.scss";
import { Grid } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import TocIcon from "@mui/icons-material/Toc";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { Link } from "react-router-dom";
export const Userfooter = () => {
  return (
    <>
      <div className="userfooter">
        <Grid container spacing={3} className="footerdata">
          <Grid item xs={12} md={12} className="footercont">
            <Link to="/user/">
              <div className="content">
                <HomeIcon />
                <span>Home</span>
              </div>
            </Link>
            <Link to="/user/market">
            <div className="content">
              <AddBusinessIcon />
              <span>Market</span>
            </div>
            </Link>
            <Link to="/user/trade">
            <div className="content">
              <LeaderboardIcon />
              <span>Trade</span>
            </div>
            </Link>
            <Link to="/user/orders">
            <div className="content">
              <TocIcon />
              <span>Orders</span>
            </div>
            </Link>
            <Link to="/user/profile">
              <div className="content">
                <ManageAccountsIcon />
                <span>Profile</span>
              </div>
            </Link>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
