import React, { useEffect, useState } from "react";
import "./admindashboard.scss";
import { Button, Divider, Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
export const AdminDashBoard = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const [pendingkyc, setPendingkyc] = useState(0);
  useEffect(() => {
    axios.get(url + "/admin/pendingkyc").then(function (resp) {
      setPendingkyc(resp.data.length);
    });
  });
  const profileupdate = async () => {
    // const resp = await axios.get(url + "/admin/getprofileupdate")
  };
  return (
    <>
      <div className="admindashboard">
        <Grid container spacing={2} className="adminboard">
          <Grid item xs={12} md={4} className="leftside">
            <div className="quickaccess">
              <Link to="/admin/pendingkyc">
                <div className="cont">
                  <h3>{pendingkyc}</h3>
                  <p>PPENDING KYC</p>
                </div>
              </Link>
              <Link to="/admin/profileupdate">
                <div className="cont">
                  <h3>0</h3>
                  <p>PROFILE UPDATE</p>
                </div>
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} md={4} className="middle">
            <div className="quickaccess">
              <Link to="/admin/bankdeposit">
                <div className="cont">
                  <h3>0</h3>
                  <p>BANK DEPOSIT</p>
                </div>
              </Link>
              <Link to="/admin/bankwithdraw">
                <div className="cont">
                  <h3>0</h3>
                  <p>BANK WITHDRAW</p>
                </div>
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} md={4} className="rightside">
            <div className="quickaccess">
              <Link to="/admin/pendingkyc">
                <div className="cont">
                  <h3>0</h3>
                  <p>PPENDING KYC</p>
                </div>
              </Link>
              <Link to="/admin/usergst">
                <div className="cont">
                  <h3>0</h3>
                  <p>OLD BALANCE</p>
                </div>
              </Link>
            </div>
          </Grid>
        </Grid>
        <Divider />
        <div className="gsttds">
          <Button variant="outlined" href="/admin/usertds" >
            TDS REPORT
          </Button>
          <Button variant="outlined" href="/admin/usergst" >
            GST REPORT
          </Button>
        </div>
      </div>
    </>
  );
};
