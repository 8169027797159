import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import "./useractiveorders.scss"
import moment from "moment";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
export const UserActOrder = (props) => {
  const [activeOrders, setActiveOrders] = useState({});
  const url = process.env.REACT_APP_HOST_ADDR;
  const apiurl = process.env.REACT_APP_API_ADDR;
  const uuid = Cookies.get("sorxuuid");
  var token = Cookies.get("token");
  useEffect(() => {
    if(token){
      axios
      .get(url + "/user/activeorders", {
        headers: {
          Authorization: uuid,
        },
      })
      .then(function (response) {
        setActiveOrders(response.data.orders);
      });
    }
    
  }, []);
  const delOrder = async (orderId, xpair) => {
    const delresp = await axios.delete(
      apiurl + "/spot/order/" + xpair + "/" + orderId,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    window.location.reload(true)
  };
  if (!activeOrders) return false;
  var rows = [];
  for (let i = 0; i < activeOrders.length; i++) {
    rows.push(activeOrders[i]);
  }
  return (
    <>
      <Grid container spacing={2} className="actorder">
        <Grid item xs={12} md={12} className="leftside">
          <Card sx={{backgroundColor:"transparent", color:'#FFF'}}>
            <CardContent>
              <h2>Active Orders</h2>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right">Date</TableCell>
                      <TableCell align="right">Pair</TableCell>
                      <TableCell align="right">Side</TableCell>
                      <TableCell align="right">Qnty</TableCell>
                      <TableCell align="right">Price</TableCell>
                      <TableCell align="right">Filled</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow
                        key={row.order_id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="right">
                          {moment(row.timestamp/1000).format("YYYY-MM-DD")}
                        </TableCell>
                        <TableCell align="right">{row.pair}</TableCell>
                        <TableCell align="right">{row.side}</TableCell>
                        <TableCell align="right">
                          {row.initial_quantity / 10e7}
                        </TableCell>
                        <TableCell align="right">{row.price / 10e7}</TableCell>
                        <TableCell align="right">
                          {row.executed_quantity / 10e7}
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            onClick={() => delOrder(row.order_id, row.pair)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
