import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import "./usertrade.scss";
import { FormInput } from "../forminput/FormInput";
import { Alert, Button } from "@mui/material";
import axios from "axios";
import * as Yup from "yup";
import Cookies from "js-cookie";
import { toast, ToastContainer } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
export const UserTradeBuy = (props) => {
  const pair = props.pair;
  const ticker = props.ticker;
  const asset = props.asset
  const assetBal = props.assetbal
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [bal, setBal] = useState(0);
  var uuid = Cookies.get("sorxuuid");
  const apiurl = process.env.REACT_APP_API_ADDR;
  const url = process.env.REACT_APP_HOST_ADDR;
  var token = Cookies.get("token");
  const validationSchema = Yup.object({
    buytotal:Yup.number('Invalid Integer').min(1).max(bal,"Low Balance")
  });
  const formik = useFormik({
    initialValues: {
      pair: pair,
      platform_id: "",
      uuid: uuid,
      base_asset: asset.base_asset,
      base_asset_bal: assetBal.base_asset_bal,
      quote_asset: asset.quote_asset,
      quote_asset_bal: assetBal.quote_asset_bal,
      buyprice: '0',
      buyamount: "0",
      buytotal: "0",
      sellprice: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setButtonDisabled(true)
      const pair = values.pair
      const side = 'Bid'
      const price = values.buyprice
      const order_type = 'Limit'
      const amount = values.buyamount
      axios.post(apiurl + "/spot/order/create", {
          pair,
          side,
          price: price*1,
          order_type,
          amount: amount*1
        },
        {
          headers:{
            Authorization: 'Bearer ' + token
          }
        }
        )
        .then(function (response) {
          notify("Order Placed Succussfully");
          formik.setFieldValue("buyamount",0)
          formik.setFieldValue("buytotal",0)
          setButtonDisabled(false)
        })
        .catch(function (error) {
          console.log(error)
          notify("Something went wrong!!!");
          setButtonDisabled(false)
        });
    },
  });
  useEffect(() => {
    if(token){
      const fetchData = async () => {
        const respMarket = await axios.get(apiurl + "/market/markets");
        const respTicker = await axios.get(apiurl + "/ticker/tickers/" + pair);
        const respBalances = await axios.get(apiurl + "/balance/balances", {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        var markets = respMarket.data.markets;
        var market = markets.filter((items) => items.symbol == pair);
        formik.setFieldValue("base_asset", market[0].base_asset);
        formik.setFieldValue("quote_asset", market[0].quote_asset);
        var ticker = respTicker.data.ticker;
        formik.setFieldValue("buyprice", ticker.high);
        var balanceSet = respBalances.data.data;
        var base_asset_bal = balanceSet.filter(
          (items) =>
            items.currency == market[0].base_asset.toLowerCase() &&
            items.wallet_type == "Spot"
        );
        var quote_asset_bal = balanceSet.filter(
          (items) =>
            items.currency == market[0].quote_asset.toLowerCase() &&
            items.wallet_type == "Spot"
        );
        formik.setFieldValue(
          "base_asset_bal",
          base_asset_bal[0].balance.toFixed(4)
        );
        setBal(quote_asset_bal[0].balance.toFixed(4))
        formik.setFieldValue(
          "quote_asset_bal",
          quote_asset_bal[0].balance.toFixed(4)
        );
      };
      fetchData();
    }
    
  }, [pair]);
  const calculate = (x) => {
    formik.setFieldValue("buytotal", formik.values.quote_asset_bal * x / 100);
    formik.setFieldValue("buyamount", parseInt((formik.values.quote_asset_bal * x / 100) / formik.values.buyprice));
  };
  const buyprice = (val) => {
    formik.setFieldValue("buyprice", val);
    formik.setFieldValue("buytotal", (val * formik.values.buyamount).toFixed(2));
  };
  const buyamount = (val) => {
    formik.setFieldValue("buyamount", val);
    formik.setFieldValue("buytotal", (val * formik.values.buyprice).toFixed(2));
  };
  const buytotal = (val) => {
    formik.setFieldValue("buyamount", (val / formik.values.buyprice).toFixed(4));
    formik.setFieldValue("buytotal", val);
  };
  //console.log(formik.values);
  const notify = (msg) => toast(msg);
  return (
    <>
    <ToastContainer />
      <div className="tradewreper">
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} autoComplete="off">
          
            <div className="form-group">
            <div className="balance">
                <div className="base_asset">
                  {formik.values.base_asset} : {formik.values.base_asset_bal}
                </div>
                <div className="quote_asset">
                  {formik.values.quote_asset} : {formik.values.quote_asset_bal}
                </div>
              </div>
              <FormInput
                type="text"
                name="buyprice"
                label="Price"
                onChange={(e) => buyprice(e.target.value)}
                value={formik.values.buyprice}
              />
              <FormInput
                type="text"
                name="buyamount"
                label={formik.values.base_asset}
                onChange={(e) => buyamount(e.target.value)}
                value={formik.values.buyamount}
              />
              <FormInput
                type="money"
                name="buytotal"
                label={formik.values.quote_asset}
                onChange={(e) => buytotal(e.target.value)}
                value={formik.values.buytotal}
              />
              <div className="calc">
                <Button variant="contained" onClick={() => calculate(25)}>
                  25%
                </Button>
                <Button variant="contained" onClick={() => calculate(50)}>
                  50%
                </Button>
                <Button variant="contained" onClick={() => calculate(75)}>
                  75%
                </Button>
                <Button variant="contained" onClick={() => calculate(100)}>
                  100%
                </Button>
              </div>
              {isButtonDisabled === false ? (
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                >
                  Buy
                </Button>
              ) : (
                <Button
                  type="button"
                  variant="outlined"
                  startIcon={<CircularProgress />}
                  size="large"
                >
                  Submiting..
                </Button>
              )}
            </div>
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};
