import React from "react";
import "./buytable.scss";
import { Divider } from "@mui/material";
export const SellTable = (props) => {
  let selldata = props.data;
  const renderTrades = () => {
    if (!selldata || !selldata.length || !selldata[0]) return `Loading...`;
    return selldata.map((selldata, index) => (
      <div key={index} className="cont">
        <div className="price">{selldata && selldata.price}</div>
        <div className="amount">
          {selldata && (selldata.amount * 1).toFixed(3)}
        </div>
        <div className="total">
          {selldata && (selldata.total * 1).toFixed(3)}
        </div>
      </div>
    ));
  };
  return (
    <div className="selldata">
      <div key={0} className="cont head">
        <div className="price">Price</div>
        <div className="amount">Amount</div>
        <div className="total">Total</div>
      </div>
      <Divider />
      {renderTrades()}
    </div>
  );
};
