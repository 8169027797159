import React, { useEffect, useState } from "react";
import "./tickerupdate.scss";
import axios from "axios";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
export const TickerUpdate = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const [data, setData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.get("https://api.coindcx.com/exchange/ticker");
      var mydata = resp.data;
      var othercoin = mydata.filter(function (entry) {
        return (
          entry.market == "BTCUSDT" ||
          entry.market == "ETHUSDT" ||
          entry.market == "MATICUSDT" ||
          entry.market == "AVAXUSDT" ||
          entry.market == "DOTUSDT" ||
          entry.market == "SHIBUSDT" ||
          entry.market == "SOLUSDT" ||
          entry.market == "DOGEUSDT" ||
          entry.market == "USDTINR" ||
          entry.market == "BNBUSDT"
        );
      });
      console.log(othercoin);
      setData(othercoin);
    };
    fetchData();
  }, []);
  const updateCoin = (market, price, low, high, change, vol) => {
    console.log(market, price, low, high, change, vol);
    axios.post(url + "/admin/tickerupdate", {
      market,
      price,
      low,
      high,
      change,
      vol,
    }).then(function(response){
        alert('Done...')
        console.log(response)
    });
  };
  if (!data) return false;
  var rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }
  return (
    <>
      <div className="tickerupdate">
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Pair</TableCell>
                <TableCell align="center">Last Price</TableCell>
                <TableCell align="center">Low</TableCell>
                <TableCell align="center">High</TableCell>
                <TableCell align="center">Change</TableCell>
                <TableCell align="center">Vol</TableCell>

                <TableCell align="center">Update</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.market}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="center">{row.market}</TableCell>
                  <TableCell align="center">{row.last_price}</TableCell>
                  <TableCell align="center">{row.low}</TableCell>
                  <TableCell align="center">{row.high}</TableCell>
                  <TableCell align="center">{row.change_24_hour}</TableCell>
                  <TableCell align="center">{row.volume}</TableCell>
                  <TableCell align="center">
                    <Button
                      onClick={() =>
                        updateCoin(
                          row.market,
                          row.last_price,
                          row.low,
                          row.high,
                          row.change_24_hour,
                          row.volume
                        )
                      }
                    >
                      UPDATE
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};
