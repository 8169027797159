import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import "./useractiveorders.scss";
import moment from "moment";
import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
export const UserAllOrder = (props) => {
  const [orders, setOrders] = useState({});
  const apiurl = process.env.REACT_APP_API_ADDR;
  var token = Cookies.get("token");
  useEffect(() => {
    if(token){
      const fetchData = async () => {
        const resp = await axios.get(apiurl + "/spot/my-trades", {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        setOrders(resp.data.trades);
      };
      fetchData();
    }
    
  }, []);
  if (!orders) return false;
  var rows = [];
  for (let i = 0; i < orders.length; i++) {
    rows.push(orders[i]);
  }
  return (
    <>
      <Grid container spacing={2} className="actorder">
        <Grid item xs={12} md={12} className="leftside">
          <Card sx={{ backgroundColor: "transparent", color: "#FFF" }}>
            <CardContent>
              <h2>Order History</h2>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right">Date</TableCell>
                      <TableCell align="right">Pair</TableCell>
                      <TableCell align="right">Side</TableCell>
                      <TableCell align="right">Qnty</TableCell>
                      <TableCell align="right">Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow
                        key={row.timestamp}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="right">
                          {moment(row.timestamp / 1000).format("YY-MMM-DD")}
                        </TableCell>
                        <TableCell align="right">{row.pair}</TableCell>
                        <TableCell align="right">{row.side}</TableCell>
                        <TableCell align="right">
                          {row.quantity / 10e7}
                        </TableCell>
                        <TableCell align="right">{row.price / 10e7}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
