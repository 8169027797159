import React, { useEffect, useState } from "react";
import "./steps.scss";
import Cookies from "js-cookie";
import { Form, FormikProvider, useFormik } from "formik";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { FormInput } from "../forminput/FormInput";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Alert, AlertTitle, Button, Divider } from "@mui/material";
import * as Yup from "yup";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const StepOne = (props) => {
  const [checktry, setCheckTry] = useState(3);
  const [btnloading, setBtnloading] = useState(false);
  const [btnstate, setBtnState] = useState(0);
  const pancheck = Cookies.get("pancheck");
  const url = process.env.REACT_APP_HOST_ADDR;
  const validationSchema = Yup.object({
    fullname: Yup.string("Invalid Name found").required(
      "Field can not be empty."
    ),
    panno: Yup.string("Invalid Pan Number found")
      .min(10, "Invalid Pan")
      .max(10, "Invalid Pan")
      .required("Field can not be empty."),
  });
  const formik = useFormik({
    initialValues: props.data,
    validationSchema,
    onSubmit: async (values) => {
      props.next(values);
    },
  });
  const panCheck = () => {
    formik.setFieldValue("panfetch",1)
    const panno = formik.values.panno;
    if (panno == "" || panno.length < 10) {
      notify("INVALID PAN NUMBER DETECTED.");
      formik.setFieldValue("panfetch",0)
      return false;
    }
    if (checktry > 0 && pancheck != "0") {
      setCheckTry(checktry - 1);
      axios
        .post(url + "/user/checkpan", {
          panno,
        })
        .then(function (response) {
          formik.setFieldValue("fullname", response.data.fullname);
          formik.setFieldValue("panfetch",2)
        })
        .catch(function (error) {
          formik.setFieldValue("panfetch",0)
          console.log(error);
        });
    } else {
      notify("You have crossed Max Check Limit! Contact support.");
      Cookies.set("pancheck", "0");
    }
  };
  useEffect(() => {
    if (pancheck == 0) {
      setCheckTry(0);
    }
  }, [pancheck]);
  const notify = (msg) => toast(msg);
  return (
    <>
      <ToastContainer />
      <div className="stepwreper">
        <FormikProvider value={formik}>
          <Form
            autoComplete="off"
            className="stepone"
            onSubmit={formik.handleSubmit}
          >
            <div className="title">
              <h2>Enter Personal Info: </h2>
              <p>All Fields are Required *</p>
            </div>
            <Divider />
            <div className="content">
              <div className="inputdata">
                <Alert severity="info">
                  <AlertTitle>MAX 3 INVALID CHECK ALLOWED.</AlertTitle>
                  Enter correct PAN No & Hit Check Button.
                </Alert>
                <div className="counter">
                  <p>Try Left:</p>
                  <p>{checktry}</p>
                </div>
                <FormInput
                  type="text"
                  name="panno"
                  label="PAN Number *"
                  onChange={formik.handleChange}
                  value={formik.values.panno}
                />
                {formik.values.panfetch == 0 ? (
                  <Button variant="outlined" onClick={panCheck}>
                    Check Pan
                  </Button>
                ) : (
                  <>
                    {formik.values.panfetch == 1 ? (
                      <Button
                        variant="outlined"
                        startIcon={<CircularProgress />}
                      >
                        Fetching Data
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        startIcon={<CheckCircleOutlineIcon />}
                      >
                        Data Fetched
                      </Button>
                    )}
                  </>
                )}
                {/* {btnloading == false ? (
                  <Button variant="outlined" onClick={panCheck}>
                    Check Pan
                  </Button>
                ) : (
                  <Button variant="outlined" startIcon={<CircularProgress />}>
                    Check Pan
                  </Button>
                )} */}
                <FormInput
                  type="text"
                  name="fullname"
                  disabled
                  label="Your Full Name *"
                  onChange={formik.handleChange}
                  value={formik.values.fullname}
                />
              </div>

              <div className="buton">
                <Button
                  variant="contained"
                  type="submit"
                  endIcon={<NavigateNextIcon />}
                >
                  NEXT
                </Button>
              </div>
            </div>
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};
