import React, { useRef, useState } from "react";
import "./tdsreport.scss";
import { Button, Card, CardContent, Grid } from "@mui/material";
import * as Yup from "yup";
import axios from "axios";
import { FormInput } from "../../components/forminput/FormInput";
import { Form, FormikProvider, useFormik } from "formik";
export const TdsReport = () => {
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const url = process.env.REACT_APP_HOST_ADDR;
  const validationSchema = Yup.object({
    startdate: Yup.date().required("Cant not be blank."),
    enddate: Yup.date().required("Cant not be blank."),
  });
  const formik = useFormik({
    initialValues: {
      startdate: "",
      enddate: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setButtonDisabled(true);
      const resp = await axios.post(url + "/admin/gettdsreport", {
        values,
      });
      if (resp.status === 201) {
        // toast.current.show({
        //   severity: "error",
        //   summary: "Error",
        //   detail: "User or Password not matched.",
        // });
        setButtonDisabled(false);
      } else if (resp.status === 200) {
        setButtonDisabled(false);
        // Navigate("/admin/");
      }
    },
  });
  return (
    <div className="tdsreport">
      <div className="grid left">
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit} autoComplete="off">
              <div className="form-group">
                <div>Start Date</div>
                <FormInput
                  type="date"
                  name="startdate"
                  autoFocus
                  onChange={formik.handleChange}
                  value={formik.values.startdate}
                />
                <div>End Date</div>
                <FormInput
                  type="date"
                  name="enddate"
                  onChange={formik.handleChange}
                  value={formik.values.enddate}
                />
                {isButtonDisabled === false ? (
                  <Button type="submit" variant="outlined" size="large">
                  Submit
                </Button>
                ) : (
                  <Button type="button" variant="outlined" size="large">
                        Submit
                      </Button>
                )}
              </div>
            </Form>
          </FormikProvider>
      </div>
    </div>
  );
};
