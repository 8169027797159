import React, { useContext, useEffect } from "react";
import { Button } from "@mui/material";
import logo from "../../images/logo.png";
import "./usernav.scss"
import Cookies from 'js-cookie';
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import { DarkModeContext } from "../../context/darkModeContext";
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
export const Usernav = () => {
  const { toggle, darkMode } = useContext(DarkModeContext);
  const Navigate = useNavigate();
  var token = Cookies.get("token");
  const apiurl = process.env.REACT_APP_API_ADDR;
  useEffect(() => {
    if (token != undefined) {
      const x = axios
      .get(apiurl + "/user/user/data", {
        headers: {
          Authorization: "Bearer " + token,
        }
      })
      .then((response) => {
        Cookies.set('sorxplatformid', response.data.platform_id)
        Cookies.set('sorxuuid', response.data.uuid)
        
      })
      .catch(function (error) {
        clearlocal()
        Navigate("/");
      });
    }else{
      console.log("User Navbar")
      clearlocal()
    }
    
  },[]);
  const clearlocal = () =>{
    Cookies.remove('token')
    Navigate("/");
  }
  return (
    <>
      <div className="usernav">
        <div className="leftside">
          <img src={logo} alt="soarlogo" />
          <Link to="/user">SOARXBITS</Link>
        </div>
        <div className="rightside">
          {darkMode ? (
            <WbSunnyOutlinedIcon onClick={toggle} />
          ) : (
            <DarkModeOutlinedIcon onClick={toggle} />
          )}
            <Button
              size="small"
              variant="outlined"
              className="btnlogin"
              startIcon={<LogoutIcon />}
              onClick={clearlocal}
            >
              Log Out
            </Button>
        </div>
      </div>
    </>
  );
};
