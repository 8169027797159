import React, { useEffect, useState } from "react";
import "./bankwithdraw.scss";
import axios from "axios";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
export const BankWithdraw = () => {
    const url = process.env.REACT_APP_HOST_ADDR;
  const [pendingwithdraw, setPendingWithdraw] = useState({});
  useEffect(() => {
    axios.get(url + "/admin/pendingwithdraw").then(function (resp) {
      console.log(resp.data);
      setPendingWithdraw(resp.data);
    });
  }, []);
  const approveWithdraw = (tranid, platform_id) => {
    axios
      .post(url + "/admin/approvewithdraw", { tranid })
      .then(function (resp) {
        window.location.reload(true)
      });
  };
  const rejectWithdraw = (tranid) => {
    axios
    .post(url + "/admin/rejectwithdraw", { tranid })
    .then(function (resp) {
      window.location.reload(true)
    });
  };
  if (!pendingwithdraw) return false;
  var rows = [];
  for (let i = 0; i < pendingwithdraw.length; i++) {
    rows.push(pendingwithdraw[i]);
  }
  return (
    <>
      <div className="bankwithdraw">
      <Grid container spacing={2} className="adminboard">
          <Grid item xs={12} md={12} className="leftside">
            <Card sx={{ backgroundColor: "transparent", color: "#FFF" }}>
              <CardContent>
                <h2>Pending KYC</h2>
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">SL. No.</TableCell>
                        <TableCell align="center">Tran Id</TableCell>
                        <TableCell align="center">Date</TableCell>
                        <TableCell align="center">Name</TableCell>
                        <TableCell align="center">Account No</TableCell>
                        <TableCell align="center">Bank</TableCell>
                        <TableCell align="center">IFSC</TableCell>
                        <TableCell align="center">Mobile</TableCell>
                        <TableCell align="center">Amount</TableCell>
                        <TableCell align="center">Pay</TableCell>
                        <TableCell align="center">Approve</TableCell>
                        <TableCell align="center">Reject</TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, index) => (
                        <TableRow
                          key={row._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">{(row.platform_id)}</TableCell>
                          <TableCell align="left">
                            {moment(row.creation_date).format("YY-MMM-DD")}
                          </TableCell>
                          <TableCell align="left">{row.user_name}</TableCell>
                          <TableCell align="left">{row.accno}</TableCell>
                          <TableCell align="left">{row.bank}</TableCell>
                          <TableCell align="left">{row.ifsc}</TableCell>
                          <TableCell align="left">{row.mobile}</TableCell>
                          <TableCell align="left">{parseInt(row.amount)}</TableCell>
                          <TableCell align="left">{parseInt(row.amount - (row.amount*1/100))}</TableCell>
                          <TableCell align="center">
                            <Button
                              variant="contained"
                              color="info"
                              onClick={() =>
                                approveWithdraw((row._id).toString(), row.platform_id)
                              }
                            >
                              Approve
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() =>
                                rejectWithdraw((row._id).toString(), row.platform_id)
                              }
                            >
                              Reject
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
