import React, { useContext, useEffect } from "react";
import { Button } from "@mui/material";
import logo from "../../images/logo.png";
import "./adminnav.scss";
import Cookies from "js-cookie";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import { DarkModeContext } from "../../context/darkModeContext";
import AppRegistrationOutlinedIcon from "@mui/icons-material/AppRegistrationOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
export const AdminNav = () => {
  const { toggle, darkMode } = useContext(DarkModeContext);
  const Navigate = useNavigate();
  var token = Cookies.get("_xtrpx");
  const apiurl = process.env.REACT_APP_API_ADDR;
  useEffect(() => {
    if (token != undefined) {
      
    } else {
      clearlocal();
    }
  }, []);
  const clearlocal = () => {
    Cookies.remove("_xtrpx");
    Navigate("/");
  };
  return (
    <>
      <div className="adminnav">
        <div className="leftside">
          <img src={logo} alt="soarlogo" />
          <Link to="/admin/dashboard">SOARXBITS</Link>
        </div>
        <div className="rightside">
          {darkMode ? (
            <WbSunnyOutlinedIcon onClick={toggle} />
          ) : (
            <DarkModeOutlinedIcon onClick={toggle} />
          )}
          <Button
            size="small"
            variant="outlined"
            className="btnlogin"
            startIcon={<LogoutIcon />}
            onClick={clearlocal}
          >
            Log Out
          </Button>
        </div>
      </div>
    </>
  );
};
