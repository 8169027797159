import { Form, Formik, FormikProvider, useFormik } from "formik";
import React, { useRef, useState } from "react";
import { FormInput } from "../forminput/FormInput";
import { Button, Divider } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import img1 from "../../images/slide-1.webp";
import * as Yup from "yup";
import { PreviewImage } from "../previewimg/PreviewImage";
export const StepCheque = (props) => {
  const [preview, setPreview] = useState(null);
  const SUPPORTED_FORMAT = ["image/jpg", "image/jpeg"];
  const validationSchema = Yup.object({
    cheque: Yup.mixed()
      .nullable()
      .required()
      .test(
        "FILE_SIZE",
        "FILE SIZE TOO BIG",
        (value) => !value || (value && value.size <= 1024 * 1024 * 1000)
      )
      .test(
        "FILE_FORMAT",
        "INVALID FILE FOUND",
        (value) => !value || (value && SUPPORTED_FORMAT.includes(value?.type))
      ),
  });
  const formik = useFormik({
    initialValues: props.data,
    validationSchema,
    onSubmit: async (values) => {
      props.next(values);
    },
  });
  const chequeRef = useRef(null);
  return (
    <>
      <div className="stepwreper">
        <FormikProvider value={formik}>
          <Form autoComplete="off" className="stepone">
            <div className="title">
              <h2>Upload Cancel Cheque</h2>
              <p>Upload Readable and Landscape Image *</p>
            </div>
            <Divider />
            <div className="content">
              <div className="inputdata">
                <div className="image">
                  {formik.values.cheque == null ? (
                    <img src={img1} alt="" />
                  ) : (
                    <PreviewImage file={formik.values.cheque} />
                  )}
                </div>
                <input
                  type="file"
                  ref={chequeRef}
                  hidden
                  name="cheque"
                  accept="image/jpg"
                  onChange={(e) => {
                    const reader = new FileReader();
                    try {
                      reader.onload = () => {
                        if (reader.readyState === 2) {
                          formik.setFieldValue("cheque", e.target.files[0]);
                          formik.setFieldValue("chequedata", reader.result);
                          setPreview(reader.result);
                        }
                      };
                    } catch (error) {
                      console.log(error);
                    }
                    reader.readAsDataURL(e.target.files[0]);
                  }}
                />
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => {
                    chequeRef.current.click();
                  }}
                >
                  Upload Cancel Cheque
                </Button>
              </div>
              <div className="buton">
                <Button
                  variant="contained"
                  type="button"
                  startIcon={<NavigateBeforeIcon />}
                  onClick={() => props.prev(formik.values)}
                >
                  Previous
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  endIcon={<NavigateNextIcon />}
                >
                  NEXT
                </Button>
              </div>
            </div>
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};
