// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.theme-light .oldbalance {
  display: flex;
  padding: 80px 40px;
}
.theme-dark .oldbalance {
  display: flex;
  padding: 80px 40px;
}`, "",{"version":3,"sources":["webpack://./src/style.scss","webpack://./src/pages/admin/oldbalance/oldbalance.scss"],"names":[],"mappings":"AA6CI;EC1CA,aAAA;EACA,kBAAA;AADJ;AD0CI;EC1CA,aAAA;EACA,kBAAA;AAGJ","sourcesContent":["$themes: (\n  light: (\n    navDefault: #c8e0d6,\n    actColor:#08e279,\n    textColor: #000,\n    textBuy: #000,\n    textSell: #000,\n    cardBorder:#dcd4d4,\n    brandText: rgb(5, 214, 221),\n    activeBg: #c8e0d6,\n    bg: white,\n    bgScroll: rgb(245, 239, 239),\n    bgTrade: rgb(234, 239, 239),\n    logo: darkblue,\n    bgSoft: #fff,\n    textColorSoft: #b3acac,\n    labelBg: #ffffff,\n    border: lightgray,\n    textBox: rgb(255, 254, 254),\n    linkColor:#346aa3  \n  ),\n  dark: (\n    navDefault: #233446,\n    actColor:#08e279,\n    textColor: whitesmoke,\n    textBuy: #f14141,\n    textSell: #59ed79,\n    cardBorder:rgb(178, 245, 223),\n    brandText: rgb(5, 214, 221),\n    activeBg: #2d9096,\n    bg: #323030,\n    bgScroll: #233446,\n    bgTrade: #233446,\n    logo: white,\n    bgSoft: #1d282f,\n    textColorSoft: lightgray,\n    labelBg: rgb(222, 246, 242),\n    border: #444,\n    textBox: rgb(222, 246, 242),\n    linkColor:#decf2a\n  ),\n);\n\n@mixin themify($themes) {\n  @each $theme, $map in $themes {\n    .theme-#{$theme} & {\n      $theme-map: () !global;\n      @each $key, $submap in $map {\n        $value: map-get(map-get($themes, $theme), \"#{$key}\");\n        $theme-map: map-merge(\n          $theme-map,\n          (\n            $key: $value,\n          )\n        ) !global;\n      }\n      @content;\n      $theme-map: null !global;\n    }\n  }\n}\n\n@function themed($key) {\n  @return map-get($theme-map, $key);\n}\n\n@mixin mobile {\n  @media (max-width: 480px) {\n    @content;\n  }\n}\n@mixin tablet {\n  @media (max-width: 960px) {\n    @content;\n  }\n}","@import \"../../../style.scss\";\n.oldbalance {\n  @include themify($themes) {\n    display: flex;\n    padding: 80px 40px;\n\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
