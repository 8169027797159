import React, { useEffect, useState } from "react";
import "./trade.scss";
import {
  Box,
  Divider,
  Grid,
  Tab,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import axios from "axios";
import Cookies from "js-cookie";
import { UserTradeBuy } from "../../../components/usertrade/UserTradeBuy";
import { UserTradeSell } from "../../../components/usertrade/UserTradeSell";
import { Intrade } from "../../../components/intrade/Intrade";
export const Trade = () => {
  const [pair, setPair] = useState("SOARXINR");
  const [data, setData] = useState({
    price: 0,
    change: 0,
    low: 0,
    high: 0,
    vol: 0,
  });
  const [asset, setAsset] = useState({
    base_asset: "",
    quote_asset: "",
  });
  const [assetbal, setAssetBal] = useState({
    base_asset_bal: "0.00",
    quote_asset_bal: "0.00",
  });
  const [tabvalue, setTabValue] = React.useState("1");
  const [pairs, setPairs] = useState({});
  let actPair = Cookies.get("soarxactivepair");
  const apiurl = process.env.REACT_APP_API_ADDR;
  const url = process.env.REACT_APP_HOST_ADDR;
  var token = Cookies.get("token");
  useEffect(() => {
    if(token){
      const fetchData = async () => {
        const respMarket = await axios.get(apiurl + "/market/markets");
        const respTicker = await axios.get(url + "/allmarket");
        const respActTicker = await axios.get(
          apiurl + "/ticker/tickers/" + actPair
        );
        const respBalances = await axios.get(apiurl + "/balance/balances", {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        var markets = respMarket.data.markets;
        var market = markets.filter((items) => items.symbol == actPair);
        setAsset({
          base_asset: market[0].base_asset,
          quote_asset: market[0].quote_asset,
        });
        var actTicker = respActTicker.data.ticker;
        setData({
          price: actTicker.last_price,
          change: actTicker.change,
          low: actTicker.low,
          high: actTicker.high,
          vol: actTicker.change,
        });
        var balanceSet = respBalances.data.data;
        var base_asset_bal = balanceSet.filter(
          (items) =>
            items.currency == market[0].base_asset.toLowerCase() &&
            items.wallet_type == "Spot"
        );
        var quote_asset_bal = balanceSet.filter(
          (items) =>
            items.currency == market[0].quote_asset.toLowerCase() &&
            items.wallet_type == "Spot"
        );
        setAssetBal({
          base_asset_bal: base_asset_bal[0].balance.toFixed(2),
          quote_asset_bal: quote_asset_bal[0].balance.toFixed(2),
        });
        setPairs(respTicker.data.market);
      };
      fetchData();
    }
    
  }, [pair]);

  const handleChange = (x) => {
    Cookies.set("soarxactivepair", x);
    setPair(x);
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  if (!pairs) return false;
  const rows = [];
  for (let i = 0; i < pairs.length; i++) {
    rows.push(pairs[i]);
  }
  return (
    <>
      <div className="maintrade">
        <Grid container spacing={0} className="pair">
          <Grid item xs={12} md={12} className="pairdata">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} className="pairdatacls">
                <div>{actPair}</div>
                <div className="item price">
                  <h3>0.00</h3>
                  <p>{data.price}</p>
                </div>
                <div className="item change">
                  <h3>24H Change</h3>
                  <p>{(data.change*1).toFixed(3)}</p>
                </div>
                <div className="item high">
                  <h3>24H High</h3>
                  <p>{data.high}</p>
                </div>
                <div className="item low">
                  <h3>24H Low</h3>
                  <p>{data.low}</p>
                </div>
                <div className="item vol">
                  <h3>24H Vol</h3>
                  <p>{(data.vol*1).toFixed(3)}</p>
                </div>
              </Grid>
              <Grid item xs={12} md={6} className="userBalance">
                <div className="baseasset">
                  {asset.base_asset} : {assetbal.base_asset_bal}
                </div>
                <div className="qouteasset">
                  {asset.quote_asset} : {assetbal.quote_asset_bal}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={0} className="tradebody">
          <Grid item xs={0} md={3} className="left">
            <div className="lftwrep">
              {rows &&
                rows.map((row) => (
                  <div className="divcont" key={row.market_id}>
                    <div
                      className="datas"
                      onClick={() => {
                        if(row.in_trade==true){
                          handleChange(row.market_id)
                        }
                      }}
                    >
                      <div className="coins">
                      <img src={row.logo} alt="" />
                        {row.base}/{row.quote}
                      </div>
                      <div className="price">{row.last_price}</div>
                      <div className="change">{(row.change*1).toFixed(3)}</div>
                    </div>
                    <Divider />
                  </div>
                ))}
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="center">
            <div className="cntrwrep">
              <div className="buysell">
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={tabvalue}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleTabChange}
                        aria-label="lab API tabs example"
                        variant="fullWidth"
                      >
                        <Tab label="BUY" value="1" className="tabbuy" />
                        <Tab label="SELL" value="2" className="tabsell" />
                        <Tab label="TRADE" value="3" className="tabtrade" />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <UserTradeBuy pair={actPair} ticker={data} asset={asset} assetbal={assetbal} />
                    </TabPanel>
                    <TabPanel value="2">
                    <UserTradeSell pair={actPair} ticker={data} asset={asset} assetbal={assetbal} />
                    </TabPanel>
                    <TabPanel value="3">
                    <Intrade pair={actPair} />
                    </TabPanel>
                  </TabContext>
                </Box>
              </div>
            </div>
          </Grid>
          <Grid item xs={0} md={3} className="right">
            <div className="rtwrep">
              {/* <Intrade pair={actPair} /> */}
            </div>
            
          </Grid>
        </Grid>
      </div>
    </>
  );
};
