import { Grid } from '@mui/material'
import "./aboutus.scss"

export const Aboutus = () => {
  return (
    <>
      <Grid container spacing={5} className="aboutus">
        <Grid item xs={12} md={3} className="community">

        </Grid>
        </Grid>
    </>
  )
}
