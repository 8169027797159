import React, { useEffect, useState } from "react";
import axios from "axios";
import * as Yup from "yup";
import "./home.scss";
import gapp from "../../../images/gpay.png";
import appleapp from "../../../images/appleapp.png";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import img1 from "../../../images/slide-1.webp";
import img2 from "../../../images/slide-2.webp";
import img3 from "../../../images/slide-3.webp";
import img4 from "../../../images/slide-4.webp";
import logo from "../../../images/soarxcoin.png";
import playstore from "../../../images/playstore.png";
import browser from "../../../images/browser.png";
import signuplogo from "../../../images/signuplogo.png";
import banklogo from "../../../images/bank.png";
import enjoylogo from "../../../images/enjoy.png";
import applestore from "../../../images/applestore.png";
import { Hotmarket } from "../../../components/hotmarket/Hotmarket";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import { FormInput } from "../../../components/forminput/FormInput";
import AppRegistrationOutlinedIcon from "@mui/icons-material/AppRegistrationOutlined";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import { FormikProvider, useFormik } from "formik";
import { Form, Link, useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Cookies from "js-cookie";
import { toast, ToastContainer } from "react-toastify";
export const Home = () => {
  const Navigate = useNavigate();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [btc, setBtc] = useState({});
  const [eth, setEth] = useState({});
  const [usdtx, setUsdtx] = useState({});
  var settings = {
    autoplay: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const url = process.env.REACT_APP_HOST_ADDR;
  const apiurl = process.env.REACT_APP_API_ADDR;
  const [primecoin, setPrimecoin] = useState({});
  const [soarx, setSoarx] = useState({});
  const [usdt, setUsdt] = useState(0);
  const validationSchema = Yup.object({
    email: Yup.mixed("Invalid OTP, Please Check!!!").required(
      "Field can not be empty."
    ),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setButtonDisabled(true);
      axios
        .post(url + "/indsignup", {
          method: "Email",
        })
        .then(function (response) {
          if (response.status === 200) {
            Cookies.set("token", response.data.token);
            localStorage.setItem("srxactiveemail", formik.values.email);
            Navigate("/user");
          }
        })
        .catch(function (error) {
          notify("Userid or password not matched!");
          setButtonDisabled(false);
          console.log(error.response.data.status);
        });
    },
  });
  useEffect(() => {
    const fetchData = async () => {
      var prime = await axios.get("https://api.coindcx.com/exchange/ticker");
      var ticker = await axios.get(apiurl + "/ticker/tickers/SOARXINR");
      //var usdtinr = await axios.get("https://api.coindcx.com/exchange/ticker");
      var usdtinr = prime.data;
      var usdtPrice = usdtinr.filter(function (entity) {
        return entity.market == "USDTINR";
      });
      var mydata = prime.data
      var othercoin = mydata.filter(function (entry) {
        return (
          entry.market === "BTCUSDT" ||
          entry.market == "ETHUSDT" ||
          entry.market == "BNBUSDT"
        );
      });
      othercoin = othercoin.sort((a, b) => {
        if (a["market"] > b["market"]) {
          return 1;
        } else if (a["market"] < b["market"]) {
          return -1;
        }
        return 0;
      });
      //console.log(prime)
      setUsdt(usdtPrice[0].last_price);

      //var btuusdt = await axios.get("https://api.coindcx.com/exchange/ticker");
      var btuusdt = prime.data;
      var btcdata = btuusdt.filter(function (entity) {
        return entity.market == "BTCUSDT";
      });
      setBtc(btcdata[0]);
      var ethdata = btuusdt.filter(function (entity) {
        return entity.market == "ETHUSDT";
      });
      setEth(ethdata[0]);
      var usdtdata = btuusdt.filter(function (entity) {
        return entity.market == "USDTINR";
      });
      setUsdtx(usdtdata[0]);

      setPrimecoin(othercoin);
      setSoarx(ticker.data.ticker);
    };
    fetchData();
  }, []);
  const notify = (msg) => toast(msg);
  if (!primecoin) return false;
  const rows = [];
  for (let i = 0; i < primecoin.length; i++) {
    rows.push(primecoin[i]);
  }
  return (
    <>
    <ToastContainer />
      <div className="home">
        <Grid container spacing={2} className="hero">
          <Grid item xs={12} md={7} className="leftside">
            <div className="title">
              <h2>Buy & Sell</h2>
              <h3>Cyrpto</h3>
              <h4>in Smoothen Way</h4>
            </div>
            <div className="downloads">
              <img loading="lazy" src={gapp} width="150" alt="" />
              <img loading="lazy" src={appleapp} width="150" alt="" />
            </div>
              <FormikProvider value={formik}>
                <Form autoComplete="off" className="quicksignup">
                  <FormInput name="email" label="Email Address" />
                  <Button
                    variant="contained"
                    className="signup"
                    size="small"
                    startIcon={<AppRegistrationOutlinedIcon />}
                  >
                    Sign Up
                  </Button>
                </Form>
              </FormikProvider>
            <Alert
              iconMapping={{
                success: <CardGiftcardOutlinedIcon fontSize="inherit" />,
              }}
              sx={{ width: "90%" }}
            >
              Register now and get up to 1000 SOARX Coin in rewards!
            </Alert>
          </Grid>
          <Grid item xs={12} md={5} className="rightside">
            <div className="primecoins">
              <Link to="/user/trade/soarx_inr">
                <div className="datas" key="soarx">
                  <div className="imgs">
                    <img src={logo} alt="soarx" />
                    <h2>SRX</h2>
                    <h3>SOARX COIN</h3>
                  </div>
                  <div className="price up">
                    {(soarx.price_change_24hr*1).toFixed(3)}
                    <ArrowDropUpOutlinedIcon />
                  </div>
                  <div className="change">
                    {(soarx.last_price / usdt).toFixed(4)}
                  </div>
                </div>
              </Link>
              {rows.map((row) => (
                <div className="datas" key={row.market}>
                  <div className="imgs">
                    <img
                      src={"/images/" + row.market.replace("USDT", "") + ".png"}
                      alt="here"
                    />
                    <h2>{row.market.replace("USDT", "")}</h2>
                    <h3>{row.market.replace("USDT", "")}</h3>
                  </div>
                  {row.change_24_hour * 1 < 0 ? (
                    <>
                      <div className="price down">
                        {(row.change_24_hour * 1).toFixed(3)}
                        <ArrowDropDownOutlinedIcon />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="price up">
                        {(row.change_24_hour * 1).toFixed(3)}
                        <ArrowDropUpOutlinedIcon />
                      </div>
                    </>
                  )}

                  <div className="change">{(row.high * 1).toFixed(4)}</div>
                </div>
              ))}

              <div className="allcoins">
                <Divider sx={{ marginBottom: 2 }} />
                <Link to="/market">View All Coins</Link>
              </div>
            </div>
            <Slider {...settings} className="slider">
              <div>
                <img src={img1} alt="" />
              </div>
              <div>
                <img src={img2} alt="" />
              </div>
              <div>
                <img src={img3} alt="" />
              </div>
              <div>
                <img src={img4} alt="" />
              </div>
            </Slider>
          </Grid>
        </Grid>
        <Grid container spacing={2} className="scrolldata">
          <Grid item xs={12} md={12}>
            <Hotmarket />
          </Grid>
        </Grid>
        <Grid container spacing={5} className="abtus">
          <Grid item xs={12} md={7} className="leftside">
            <div className="title">
              <h2>why soarxbits?</h2>
            </div>
            <div className="intro">
              <h2>Introduction:</h2>
              <p>
                SOARXBITS is a cryptocurrency trading platform designed
                specially to assure smooth experience of trading.
              </p>
              <p>
                It is a reliable and secure way to buy, sell and trade crypto
                with low fee and unmatched liquidity. You can try yourself with
                SOARXBITS demo trading account, apply for free crypto, and play
                games to earn even more crypto.
              </p>
              <p>
                Best of all, we give you access to the widest variety of
                cryptocurrencies on the market.
              </p>
            </div>
            <div className="keyfeature">
              <h2>key features:</h2>
              <Alert severity="success">Interactive User Interface.</Alert>
              <Alert severity="success">Hassall free execution.</Alert>
              <Alert severity="success">Quick Buy and Sell Mechanism.</Alert>
              <Alert severity="success">Interactive User Interface.</Alert>
              <Alert severity="success">24 X 7 Live support system.</Alert>
            </div>
          </Grid>
          <Grid item xs={12} md={5} className="rightside">
            <div className="title">
              <h2>Trade anywhere!</h2>
              <p>Anytime, anywhere. Trade crypto on your terms @ 24 x 7.</p>
            </div>
            <Divider />
            <div className="data">
              <div className="item">
                <img src={browser} alt="" />
                <div className="child">
                  <h2>Any Browser</h2>
                  <p>Flee free to use any browser!</p>
                </div>
              </div>
              <div className="item">
                <img src={applestore} alt="" />
                <div className="child">
                  <h2>App Store</h2>
                  <p>Download for iOS!</p>
                </div>
              </div>
              <div className="item">
                <img src={playstore} alt="" />
                <div className="child">
                  <h2>Play Store</h2>
                  <p>Download for Android!</p>
                </div>
              </div>
            </div>
            <Alert severity="success" color="warning">
              Easily Buy and Sell BTC, ETH, SOARX, and More in smoothen way
            </Alert>
            <Alert severity="success" color="warning">
              Access Real-Time Cryptocurrency Prices
            </Alert>
            <Alert severity="success" color="warning">
              Compare Cryptocurrency Prices Across Multiple Exchanges
            </Alert>
          </Grid>
        </Grid>
        <Grid container spacing={5} className="quickguide">
          <Grid item xs={12} md={12} className="data">
            <div className="title">
              <div className="content">
                <h2>How it works?</h2>
                <p>We made it very easy to trade in platform</p>
                <p>3 steps of ready trade.</p>
              </div>
            </div>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              divider={<Divider orientation="vertical" flexItem />}
              spacing={{ xs: 3, sm: 4, md: 4 }}
              className="item"
            >
              <div className="content">
                <img src={signuplogo} alt="" />
                <h2>STEP I</h2>
                <h3>Sign Up</h3>
                <p>
                  Keep your PAN CARD and AADHAR CARD with you while signing up.
                </p>
              </div>
              <div className="content">
                <img src={banklogo} alt="" />
                <h2>STEP II</h2>
                <h3>Bank Verification</h3>
                <p>
                  Before submitting bank verification data, please keep cancelled
                  cheque with you.
                </p>
              </div>
              <div className="content">
                <img src={enjoylogo} alt="" />
                <h2>STEP III</h2>
                <h3>Enjoy Trading</h3>
                <p>
                  Almost all trading features are available in your platform.
                </p>
              </div>
            </Stack>
          </Grid>
        </Grid>
        <div className="frequant">
          <div className="title">
            <h2>Frequently Asked Questions</h2>
            <p>Lets Drive into</p>
          </div>
          <div className="data">
            <Accordion className="item">
              <AccordionSummary
                expandIcon={<ArrowCircleDownIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography>What is Crypto Exchange?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                Exchanges for cryptocurrencies are online markets where users can purchase and trade digital currencies such as Tether, Ethereum, Soarx, and Bitcoin. The expanding cryptocurrency exchange with a broad vision is SOARXBITS.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ArrowCircleDownIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography>why soarxbits?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                A cryptocurrency trading platform called SOARXBITS was created with the goal of ensuring a seamless trading experience.
                </Typography>
                <Typography>
                With minimal fees and unrivaled liquidity, it's a dependable and safe method to purchase, sell, and exchange cryptocurrencies. You can apply for free cryptocurrency, test out the SOARXBITS demo trading account, and play games to earn even more cryptocurrency.
                </Typography>
                <Typography>
                The best part is that we provide you with the largest selection of cryptocurrencies available.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ArrowCircleDownIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography>How to Trade</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                Trading involves buying and selling financial assets to profit from price movements. To start, choose a market, educate yourself, open a trading account with a broker, fund it, and develop a trading plan. Analyze the market, place trades, monitor them, and adjust your strategy as needed. Manage risk by using stop-loss orders and diversification. Success requires knowledge, discipline, and effective risk management.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <div className="listwithus">
          <div className="title">
            <h2>list your crypto asset with us</h2>
            <p>know, why you should?</p>
          </div>
          <Grid container spacing={2} className="reason">
            <Grid item xs={12} md={5} className="leftside">
              <div className="content">
                <div className="head">
                  <CheckCircleIcon />
                  <p>Rapid Market Entry with Quick Integration</p>
                </div>
                <div className="data">
                  <p>
                    Launch quickly with our streamlined process, saving time.
                  </p>
                </div>
              </div>
              <div className="content">
                <div className="head">
                  <CheckCircleIcon />
                  <p>Affordable Listing Plans for Every Stage</p>
                </div>
                <div className="data">
                  <p>
                    Launch quickly with our streamlined process, saving time.
                  </p>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={7} className="rightside"></Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};
