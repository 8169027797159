import React, { useState } from "react";
import "./officelogin.scss";
import { Field, Form, FormikProvider, useFormik } from "formik";
import { FormInput } from "../../../components/forminput/FormInput";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import login from "../../../images/login.png";
import Cookies from "js-cookie";
import axios from "axios";
import * as Yup from "yup";
export const OfficeLogin = () => {
    const Navigate = useNavigate();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const url = process.env.REACT_APP_HOST_ADDR;
  const validationSchema = Yup.object({
    email: Yup.mixed("Invalid OTP, Please Check!!!").required(
      "Field can not be empty."
    ),
    password: Yup.string()
      .min(8, "Minimum 8 Charecter Required")
      .matches(/[a-zA-Z]/, "Password can only contain Latin letters.")
      .required("Invalid Password."),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      disableButton();
      var resp = await axios.post(url + "/admin/adminlogin",{
        values
      })
      if(resp.status==200){
        Cookies.set("_xtrpx",'_xtppy')
        Navigate("/office/");
      }else{
        notify("User Name Or Password Not Matched")
        setButtonDisabled(false)
      }
    },
  });
  const disableButton = () => {
    setButtonDisabled(true);
  };
  const notify = (msg) => toast(msg);
  return (
    <>
    <ToastContainer />
    <Grid container spacing={2} className="officelogin">
      <Grid item xs={12} md={6} className="leftside">
        <Card sx={{ backgroundColor: "transparent" }}>
          <CardContent>
            <h2>Welcome back to soarxbits Admin!</h2>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="form-group">
                  <div>Use Email Address: </div>
                  <FormInput
                    type="email"
                    name="email"
                    label="Email Address"
                    autoFocus
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  <FormInput
                    type="password"
                    name="password"
                    label="Password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                  />
                  {isButtonDisabled === false ? (
                    <Button type="submit" variant="outlined" size="large">
                      Submit
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      variant="outlined"
                      startIcon={<CircularProgress />}
                      size="large"
                    >
                      Submiting..
                    </Button>
                  )}
                </div>
              </Form>
            </FormikProvider>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6} className="rightside">
        <img src={login} alt="" />
      </Grid>
    </Grid>
  </>
  )
}
