import React, { useEffect, useState } from "react";
import "./mobileverify.scss";
import { Button, Card, CardContent, Divider, Grid } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import { FormInput } from "../../../components/forminput/FormInput";
import verrification from "../../../images/verrification.png";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import { Form, Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import Cookies from "js-cookie";
import axios from "axios";
import * as Yup from "yup";
export const MobileVerify = () => {
  const uuid = Cookies.get("sorxuuid");
  const Navigate = useNavigate();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [isotpButtonDisabled, setotpButtonDisabled] = useState(false);
  const url = process.env.REACT_APP_HOST_ADDR;
  const actemail = localStorage.getItem("srxactiveemail")
  const platform_id = Cookies.get("sorxplatformid");
  const validationSchema = Yup.object({
    mobile: Yup.number("Invalid Mobile Number!")
      .min(1111111111, "Invalid Mobile Number!")
      .max(9999999999, "Invalid Mobile Number!")
      .required("Field can not be empty."),
    otp: Yup.number("Invalid OTP").required("Invalid Password."),
  });
  const formik = useFormik({
    initialValues: {
      uuid: uuid,
      platform_id:'',
      email:'',
      mobile: '',
      otp: '',
      otp_for: 'Mobile'
    },
    validationSchema,
    onSubmit: async (values) => {
      //console.log(values)
      disableButton();
      axios
        .post(url + "/user/mobauthcomplete", {
          values,
        })
        .then(function (response) {
          console.log(response.status)
          if (response.status === 200) {
            Navigate(
              "/displaymsg/Success/Mobile Number Verified Successfully. Please Proceed to KYC UPDATE/kycdata/2"
            );
          }
        })
        .catch(function (error) {
          notify("Userid or password not matched!");
          setButtonDisabled(false);
          console.log(error.response.data.status);
        });
    },
  });
  useEffect(()=>{
    formik.setFieldValue('email', actemail)
    formik.setFieldValue('platform_id', platform_id)
  },[])
  const disableButton = () => {
    setButtonDisabled(true);
  };
  const disablegetButton = () => {
    setotpButtonDisabled(true);
  };
  const sendOtp = () => {
    setotpButtonDisabled(true);
    var mobile = formik.values.mobile;
    
    axios
      .post(url + "/user/mobauthinit", {
        platform_id,
        actemail,
        mobile,
        otp_for:'Mobile'
      })
      .then(function (response) {
        notify("Mobile OTP!!! OTP has sent to your Mobile Number");
      })
      .catch(function (error) {
        notify("Sorry!!! Something went wrong.");
        setButtonDisabled(false);
      });
  };
  const notify = (msg) => toast(msg);
  return (
    <>
      <ToastContainer />
      <Grid container spacing={2} className="mobileverify">
        <Grid item xs={12} md={5} className="leftside">
          <Card sx={{backgroundColor:"transparent"}}>
            <CardContent>
            <h2>Mobile Verification!!!</h2>
            <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit} autoComplete="off">
              <div className="form-group">
                <div>Enter 10 Digit Mobile Number: [No Country Code] </div>
                <Divider />
                <FormInput
                  type="text"
                  name="mobile"
                  label="Mobile Number"
                  autoFocus
                  onChange={formik.handleChange}
                  value={formik.values.mobile}
                />
                <Button
                  type="button"
                  variant="outlined"
                  disabled={isotpButtonDisabled}
                  startIcon={<PhoneAndroidIcon />}
                  color="warning"
                  onClick={sendOtp}
                >
                  Get OTP
                </Button>
                <FormInput
                  type="text"
                  name="otp"
                  label="OTP"
                  onChange={formik.handleChange}
                  value={formik.values.otp}
                />

{isButtonDisabled === false ? (
                  <Button type="submit" variant="outlined" size="large">
                    Submit
                  </Button>
                ) : (
                  <Button
                    type="button"
                    variant="outlined"
                    startIcon={<CircularProgress />}
                    size="large"
                  >
                    Submiting..
                  </Button>
                )}
              </div>
            </Form>
          </FormikProvider>
            </CardContent>
          </Card>
          
          
        </Grid>
        <Grid item xs={12} md={7} className="rightside">
            <img src={verrification} alt="" />
          
        </Grid>
      </Grid>
    </>
  );
};
