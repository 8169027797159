import { Alert, Button } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { FormInput } from "../forminput/FormInput";
import Cookies from "js-cookie";
import axios from "axios";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
export const AddressUpdate = (props) => {
  const mob =  props.mobile;
  const Navigate = useNavigate();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [isotpButtonDisabled, setotpButtonDisabled] = useState(false);
  const [data, setData] = useState({
    receipt_link: "",
  });
  var platform_id = Cookies.get("sorxplatformid");
  const actemail = localStorage.getItem("srxactiveemail");
  const url = process.env.REACT_APP_HOST_ADDR;
  const validationSchema = Yup.object({
    mobile: Yup.number("Invalid Mobile Number!").required(
      "Field can not be empty."
    ),
    otp: Yup.number("Invalid OTP").required("Invalid Password."),
    receiptlink: Yup.mixed().required("Field can not be blanked!"),
  });
  const formik = useFormik({
    initialValues: {
      platformid: platform_id,
      email: actemail,
      mobile: mob,
      otp: "",
      otp_for: "Address",
      receiptlink: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      disableButton();
      axios
        .post(url + "/user/receiptlinkupdate", {
          values,
        })
        .then(function (response) {
          console.log(response.data);
          console.log("Status: ", response.status);
          if (response.status === 200) {
            Navigate(
              "/displaymsg/Success/Address Updated Successfully. You can proceed with withdraw/cryptowithdraw/2"
            );
          }
        })
        .catch(function (error) {
          notify("Userid or password not matched!");
          console.log(error.response.data.status);
        });
    },
  });
    useEffect(() => {
      axios
        .post(url + "/user/getbankdetail", {
          platform_id,
        })
        .then(function (response) {
          if (response.data.data != null) {
            formik.setFieldValue('mobile',response.data.data.mobile)
            setData(response.data.data);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }, [data.receipt_link]);
  const disableButton = () => {
    setButtonDisabled(true);
  };
  const sendOtp = () => {
    setotpButtonDisabled(true);
    var mobile = formik.values.mobile;
    const actemail = localStorage.getItem("srxactiveemail");
    axios
      .post(url + "/user/mobauthinit", {
        platform_id,
        actemail,
        mobile,
        otp_for: "Address",
      })
      .then(function (response) {
        notify("Mobile OTP!!! OTP has sent to your Mobile Nuber");
        formik.setFieldValue("otp_for", "Address");
      })
      .catch(function (error) {
        notify("Sorry!!! Something went wrong.");
        setButtonDisabled(false);
      });
  };
  const notify = (msg) => toast(msg);
  return (
    <>
      <div className="title">
        <p>Crypto Withdraw</p>
      </div>
      <Alert severity="warning">
        Seems you have not updated received link.
      </Alert>
      <Alert>OTP will be sent to your registered mobile.</Alert>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit} autoComplete="off">
          <div className="form-group">
            <FormInput
              type="text"
              name="mobile"
              label="Mobile Number"
              disabled
              onChange={formik.handleChange}
              value={formik.values.mobile}
            />
            <Button
              type="button"
              variant="outlined"
              disabled={isotpButtonDisabled}
              startIcon={<PhoneAndroidIcon />}
              color="warning"
              onClick={sendOtp}
            >
              Get OTP
            </Button>
            <FormInput
              type="text"
              name="otp"
              label="OTP"
              onChange={formik.handleChange}
              value={formik.values.otp}
            />
            <FormInput
              type="text"
              name="receiptlink"
              label="Receive Link"
              onChange={formik.handleChange}
              value={formik.values.receiptlink}
            />
            <Button
              type="submit"
              variant="contained"
              disabled={isButtonDisabled}
              startIcon={<PhoneAndroidIcon />}
              color="secondary"
            >
              Confirm
            </Button>
          </div>
        </Form>
      </FormikProvider>
    </>
  );
};
