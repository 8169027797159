import React from "react";
import "./signup.scss";
import Button from "@mui/material/Button";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import GoogleIcon from "@mui/icons-material/Google";
import AppleIcon from "@mui/icons-material/Apple";
import { Box, Card, CardContent, Divider, Grid } from "@mui/material";
import signup from "../../../images/signup.png";
import { Link } from "react-router-dom";
export const Signup = () => {
  return (
    <>
      <Grid container spacing={2} className="signuppg">
        <Grid item xs={12} md={6} className="leftside">
          <Card sx={{ backgroundColor: "transparent" }}>
            <CardContent>
              <div className="carddata">
                <h2>Welcome to soarxbits!</h2>
                <Divider />
                <Button
                  href="/indsignup"
                  variant="contained"
                  startIcon={<PermIdentityOutlinedIcon />}
                  sx={{ width: "100%" }}
                >
                  Signup with Email Address
                </Button>
                <Divider>OR</Divider>
                <Button
                  variant="outlined"
                  sx={{ borderColor: "#656cff", color: "#656cff" }}
                  startIcon={<GoogleIcon />}
                >
                  Continue with Google
                </Button>
                <Button
                  variant="outlined"
                  color="info"
                  startIcon={<AppleIcon />}
                >
                  Continue with Apple
                </Button>
                <div>
                  Already have account?{" "}
                  <Link to="/login" className="link">
                    Log In
                  </Link>{" "}
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} className="rightside">
          <Box sx={{ width: "100%" }}>
            <img src={signup} alt="" />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
