import { Button, Divider } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { FormInput } from "../forminput/FormInput";
import { useNavigate } from "react-router-dom";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CircularProgress from "@mui/material/CircularProgress";
import Cookies from "js-cookie";
import axios from "axios";
import * as Yup from "yup";
export const FinalDisplay = (props) => {
  const Navigate = useNavigate();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const url = process.env.REACT_APP_HOST_ADDR;
  var platformId = Cookies.get("sorxplatformid");
  var email = localStorage.getItem("srxactiveemail");
  const validationSchema = Yup.object({});
  const formik = useFormik({
    initialValues: props.data,
    validationSchema,
    onSubmit: async (values) => {
      //disableButton();
      axios
        .post(
          url + "/user/kycupload",
          {
            uuid: values.uuid,
            email: email,
            platformid: platformId,
            fullname: values.fullname,
            pan: values.panno,
            aadhar: values.aadhar,
            bankname: values.bankname,
            accno: values.accno,
            branch: values.branch,
            ifsc: values.ifsc,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.status);
            Navigate(
              "/displaymsg/Success/Kyc Verified Successfully. Upload Documents/docupload/2"
            );
            // Navigate("/user");
          }
          // if (response.status == 201) {
          //   Navigate("/displaymsg/Success/Account Created Successfully. Please Proceed to Login/login/1");
          // }
        })
        .catch(function (error) {
          notify("Userid or password not matched!");
          //setButtonDisabled(false);
          console.log(error);
        });
    },
  });
  const disableButton = () => {
    setButtonDisabled(true);
  };
  const notify = (msg) => toast(msg);
  return (
    <>
      <ToastContainer />
      <div className="finaldisplay">
        <FormikProvider value={formik}>
          <Form
            autoComplete="off"
            className="stepone"
            onSubmit={formik.handleSubmit}
            encType="multipart/form-data"
          >
            <div className="title">
              <h2>Check and Submit: </h2>
              <p>All Fields are Required *</p>
            </div>

            <Divider />
            <div className="content">
              <div className="inputdata">
                <FormInput
                  type="text"
                  name="fullname"
                  disabled
                  label="Your Full Name *"
                  onChange={formik.handleChange}
                  value={formik.values.fullname}
                />
                <FormInput
                  type="text"
                  name="pan"
                  label="PAN Number *"
                  disabled
                  onChange={formik.handleChange}
                  value={formik.values.panno}
                />
                <FormInput
                  type="text"
                  name="aadhar"
                  label="Aadhaar Number *"
                  disabled
                  onChange={formik.handleChange}
                  value={formik.values.aadhar}
                />
                <FormInput
                  type="text"
                  name="bankname"
                  label="Bank Name *"
                  disabled
                  onChange={formik.handleChange}
                  value={formik.values.bankname}
                />
                <FormInput
                  type="text"
                  name="accno"
                  label="Account Number *"
                  disabled
                  onChange={formik.handleChange}
                  value={formik.values.accno}
                />
                <FormInput
                  type="text"
                  name="branch"
                  label="Branch Name *"
                  disabled
                  onChange={formik.handleChange}
                  value={formik.values.branch}
                />
                <FormInput
                  type="text"
                  name="ifsc"
                  label="IFSC *"
                  disabled
                  onChange={formik.handleChange}
                  value={formik.values.ifsc}
                />
              </div>
              <div className="buton">
                <Button
                  variant="contained"
                  type="button"
                  startIcon={<NavigateBeforeIcon />}
                  onClick={() => props.prev(formik.values)}
                >
                  Previous
                </Button>

                {isButtonDisabled === false ? (
                  <Button type="submit" variant="outlined" size="large">
                    Submit
                  </Button>
                ) : (
                  <Button
                    type="button"
                    variant="outlined"
                    startIcon={<CircularProgress />}
                    size="large"
                  >
                    Submiting..
                  </Button>
                )}
              </div>
            </div>
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};
