import React, { useEffect, useState } from "react";
import "./inrwithdraw.scss";
import { Alert, Box, Button, Card, CardContent, Grid } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { FormInput } from "../../../components/forminput/FormInput";
import axios from "axios";
import * as Yup from "yup";
import Cookies from "js-cookie";
import { toast, ToastContainer } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
export const InrWithdraw = () => {
  const Navigate = useNavigate();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [inrList, setInrList] = useState({});
  const [data, setData] = useState(0);
  const apiurl = process.env.REACT_APP_API_ADDR;
  const url = process.env.REACT_APP_HOST_ADDR;
  const email = localStorage.getItem("email");
  var platform_id = Cookies.get("sorxplatformid");
  var token = Cookies.get("token");
  var uuid = Cookies.get("sorxuuid");
  const validationSchema = Yup.object({
    accno: Yup.number("Invalid Transection Number, Please Check!!!").required(
      "Field can not be empty."
    ),
    //amount: Yup.number().min(200, "Minumum 200 is allowed").lessThan(Yup.ref('balance')),
    amount: Yup.number().min(200, "Minumum 200 is allowed").max(7000),
  });
  const formik = useFormik({
    initialValues: {
      platform_id: platform_id,
      uuid: uuid,
      user_name: "",
      mobile: "",
      accno: "",
      bank: "",
      branch: "",
      ifsc: "",
      amount: "",
      balance: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setButtonDisabled(true);
      axios
        .post(url + "/user/inrwithdraw", {
          values,
        },
      {
        headers: {
          Authorization: token,
        },
      })
        .then(function (response) {
          if (response.status == 201) {
            notify(response.data.error);
            disableButton(false);
          } else if (response.status == 200) {
            Navigate(
              "/displaymsg/Success/Your request submitted successfully. Please Proceed to INR WITHDRAW/inrwithdraw/2"
            );
          } else {
            notify("Something went wrong!!!");
            disableButton(false);
          }
        })
        .catch(function (error) {
          notify("Something went wrong!!!");
          disableButton(false);
        });
    },
  });
  const disableButton = (x) => {
    setButtonDisabled(x);
  };
  useEffect(() => {
    if (token) {
      const fetchData = async () => {
        const bankdetails = await axios.post(url + "/user/getbankdetail", {
          platform_id,
        });
        //console.log(bankdetails.data.data)
        formik.setFieldValue("user_name", bankdetails.data.data.user_name);
        formik.setFieldValue("mobile", bankdetails.data.data.mobile);
        formik.setFieldValue("accno", bankdetails.data.data.accno);
        formik.setFieldValue("bank", bankdetails.data.data.bank);
        formik.setFieldValue("branch", bankdetails.data.data.branch);
        formik.setFieldValue("ifsc", bankdetails.data.data.ifsc);
        const balance = await axios.get(
          apiurl + "/balance/balances?currency=inr&wallet_type=Spot",
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        formik.setFieldValue("balance", balance.data.data[0].balance);
        //console.log(balance)
        const withdrawlist = await axios.post(url + "/user/getinrwithdraw", {
          platform_id,
        });
        console.log(withdrawlist.data.data);
        setInrList(withdrawlist.data.data);
      };
      fetchData();
    }
  }, []);
  const notify = (msg) => toast(msg);
  const calculate = (x) => {
    formik.setFieldValue("amount", (formik.values.balance * x) / 100);
  };
  const columns = [
    {
      field: "id",
      headerName: "Tran Id",
      width: 220,
      editable: true,
    },
    { field: "trandate", headerName: "Date", width: 90 },

    {
      field: "amount",
      headerName: "Amount",
      type: "number",
      width: 100,
      editable: true,
    },
    {
      field: "status",
      headerName: "Status",
      type: "number",
      width: 110,
      editable: true,
    },
  ];
  if (!inrList) return false;
  const rows = [];
  for (let i = 0; i < inrList.length; i++) {
    var str = {
      id: inrList[i]._id,
      trandate: inrList[i].tran_date,
      amount: inrList[i].amount,
      status: inrList[i].status,
    };
    rows.push(str);
  }
  return (
    <>
      <ToastContainer />
      <div className="inrwithdraw">
        <Grid container spacing={2} className="inrcontent">
          <Grid item xs={12} md={6} className="leftside">
            <Card sx={{ backgroundColor: "transparent" }} className="mycard">
              <CardContent>
                <h5>INR WITHDRAW:</h5>
                <FormikProvider value={formik}>
                  <Form onSubmit={formik.handleSubmit} autoComplete="off">
                    <div className="form-group">
                      <div>All Fields are required *.</div>
                      <FormInput
                        type="text"
                        name="accno"
                        label="Account Number"
                        disabled
                        onChange={formik.handleChange}
                        value={formik.values.accno}
                      />
                      <Alert>Amount will be transfer to this account.</Alert>
                      <Alert>
                        Min: 100, Max: 300000, Fees: 15.00 INR, Procession Time
                        is 3 business days..
                      </Alert>
                      <div className="available">
                        <p>Available Balance</p>
                        <p>{data.toFixed(2)}</p>
                      </div>
                      <FormInput
                        type="money"
                        name="balance"
                        label="Available"
                        disabled
                        onChange={formik.handleChange}
                        value={formik.values.balance}
                      />
                      <FormInput
                        type="money"
                        name="amount"
                        label="Amount to be withdraw"
                        onChange={formik.handleChange}
                        value={formik.values.amount}
                      />
                      <div className="calc">
                        <Button
                          variant="outlined"
                          onClick={() => calculate(25)}
                        >
                          25%
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() => calculate(50)}
                        >
                          50%
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() => calculate(75)}
                        >
                          75%
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() => calculate(100)}
                        >
                          100%
                        </Button>
                      </div>
                    </div>
                    {isButtonDisabled === false ? (
                      <Button type="submit" variant="outlined" size="large">
                        Submit
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        variant="outlined"
                        startIcon={<CircularProgress />}
                        size="large"
                      >
                        Submiting..
                      </Button>
                    )}
                  </Form>
                </FormikProvider>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} className="rightside">
            <Card>
              <CardContent>
                <h3>Withdraw History</h3>
                <Box sx={{ height: 400, width: "100%", marginTop: 3 }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 5,
                        },
                      },
                    }}
                    pageSizeOptions={[5]}
                    disableRowSelectionOnClick
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
