import { Form, Formik, FormikProvider, useFormik } from "formik";
import React, { useRef, useState } from "react";
import { FormInput } from "../forminput/FormInput";
import { Button, Divider } from "@mui/material";
import "./steps.scss"
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CircularProgress from '@mui/material/CircularProgress';
import { PreviewImage } from "../previewimg/PreviewImage";
import Cookies from "js-cookie";
import axios from "axios";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
export const StepSeven = (props) => {
  const Navigate = useNavigate();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const url = process.env.REACT_APP_HOST_ADDR;
  var platformId = Cookies.get("sorxplatformid");
  var email = localStorage.getItem('srxactiveemail')
  const validationSchema = Yup.object({});
  const formik = useFormik({
    initialValues: props.data,
    validationSchema,
    onSubmit: async (values) => {
      disableButton();
      axios
        .post(
          url + "/user/upload",
          {
            uuid: values.uuid,
            email:email,
            platformid: platformId,
            panimage: values.panimage,
            aadharfrontimage: values.aadharfrontimage,
            aadharback: values.aadharback,
            cheque: values.cheque,
            selfy:values.selfy
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.status);
            Navigate("/displaymsg/Success/Document uploaded Successfully. Enjoy trading/nowhere/2");
          }
        })
        .catch(function (error) {
          notify("Something went wrong! Try after sometimes.");
          console.log(error);
        });
    },
  });
  const disableButton = () => {
    setButtonDisabled(true);
  };
  console.log(formik.values)
  const notify = (msg) => toast(msg);
  return (
    <>
      <ToastContainer />
      <div className="finaldisplay">
        <FormikProvider value={formik}>
          <Form
            autoComplete="off"
            className="stepone"
            onSubmit={formik.handleSubmit}
            encType="multipart/form-data"
          >
            <div className="title">
              <h2>Check and Submit: </h2>
              <p>All Fields are Required *</p>
            </div>

            <Divider />
            <div className="content">
              <div className="imgcontainer">
                <div className="pancard">
                  <div className="title">PAN:</div>
                  <PreviewImage
                    file={formik.values.panimage}
                    imagetype="selfy"
                    className="selfy"
                  ></PreviewImage>
                </div>
                <div className="aadharfront">
                  <div className="title">AADHAAR FRONT:</div>
                  <PreviewImage
                    file={formik.values.aadharfrontimage}
                    imagetype="selfy"
                    className="selfy"
                  ></PreviewImage>
                </div>
                <div className="aadharfront">
                  <div className="title">AADHAAR BACK:</div>
                  <PreviewImage
                    file={formik.values.aadharback}
                    imagetype="selfy"
                    className="selfy"
                  ></PreviewImage>
                </div>
                <div className="bank">
                  <div className="title">CHEQUE BOOK:</div>
                  <PreviewImage
                    file={formik.values.cheque}
                    imagetype="selfy"
                    className="selfy"
                  ></PreviewImage>
                </div>
                <div className="aadharfront">
                  <div className="title">SELFY:</div>
                  <PreviewImage
                    file={formik.values.selfy}
                    imagetype="selfy"
                    className="selfy"
                  ></PreviewImage>
                </div>
              </div>
              <div className="buton">
                <Button
                  variant="contained"
                  type="button"
                  startIcon={<NavigateBeforeIcon />}
                  onClick={() => props.prev(formik.values)}
                >
                  Previous
                </Button>

                {isButtonDisabled === false ? (
                <Button type="submit" variant="outlined" size="large" >
                  Submit
                </Button>
              ) : (
                <Button type="button" variant="outlined" startIcon={<CircularProgress />} size="large">
                  Submiting..
                </Button>
              )}
              </div>
            </div>
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};
