import React, { useEffect, useState } from "react";
import "./cryptodeposit.scss";
import Cookies from "js-cookie";
import axios from "axios";
import CopyAllOutlinedIcon from "@mui/icons-material/CopyAllOutlined";
import {
  Alert,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
} from "@mui/material";
export const CryptoDeposit = () => {
  const [data, setData] = useState("");
  const apiurl = process.env.REACT_APP_API_ADDR;
  var token = Cookies.get("token");
  useEffect(() => {
    axios
      .get(apiurl + "/addresses/address/create/evm", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        setData(response.data.address);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  const copyText = () => {
    navigator.clipboard.writeText(data);
  };
  return (
    <>
      <Grid container spacing={2} className="cryptodeposit">
        <Grid item xs={12} md={6} className="leftside">
          <Card>
            <CardContent className="body">
              <h3>Deposit Address:</h3>
              <Divider />
              <Alert>Please deposit only EVM Compatible Coin/Token</Alert>
              <Alert severity="warning">Your coin may lost permanantly.</Alert>
              <div className="receiptlink">
                <div className="head">Your Receipt Link:</div>
                <div className="cont">
                <IconButton aria-label="delete" onClick={copyText}>
                    <CopyAllOutlinedIcon />
                  </IconButton>
                  {/* <p>Please Do not deposit coin. App is in Upgrade mode.</p> */}
                  <p>{data}</p>
                  
                </div>
              </div>
              <Alert severity="warning">Your coin may lost permanantly.</Alert>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
