import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import "./usertds.scss";
import * as Yup from "yup";
import { Button, Card, CardContent } from "@mui/material";
import { FormInput } from "../../../components/forminput/FormInput";
import { Form, FormikProvider, useFormik } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
export const UserTds = () => {
  const [products, setProducts] = useState([]);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const url = process.env.REACT_APP_HOST_ADDR;
  const validationSchema = Yup.object({
    //start:Yup.number('Invalid Integer').min(1).max(bal,"Low Balance")
  });
  const formik = useFormik({
    initialValues: {
      startdate: "",
      enddate: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      //setButtonDisabled(true);
      alert("H1")
      axios
        .post(url + "/admin/gettdsreport", {
          values,
        })
        .then(function (response) {
          console.log(response.data.data);
          setProducts(response.data.data);
          setButtonDisabled(false);
        })
        .catch(function (error) {
          console.log(error);
          //   notify("Something went wrong!!!");
          setButtonDisabled(false);
        });
    },
  });
  return (
    <div className="usertds">
      <Card>
        <CardContent>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit} autoComplete="off">
              <div className="form-group">
                <FormInput
                  type="date"
                  name="startdate"
                  onChange={formik.handleChange}
                  value={formik.values.startdate}
                />
                <FormInput
                  type="date"
                  name="enddate"
                  onChange={formik.handleChange}
                  value={formik.values.enddate}
                />
                {isButtonDisabled === false ? (
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    size="large"
                  >
                    GET DATA
                  </Button>
                ) : (
                  <Button
                    type="button"
                    variant="outlined"
                    startIcon={<CircularProgress />}
                    size="large"
                  >
                    PROCEECING..
                  </Button>
                )}
              </div>
            </Form>
          </FormikProvider>
        </CardContent>
      </Card>
      <div className="data">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>SL. NO.</TableCell>
                <TableCell>DATE</TableCell>
                <TableCell align="left">USER NAME</TableCell>
                <TableCell align="left">PAN</TableCell>
                <TableCell align="left">BASE AMT</TableCell>
                <TableCell align="left">CHARGED</TableCell>
                <TableCell align="left">GROSS AMOUNT</TableCell>
                <TableCell align="left">TDS</TableCell>
                <TableCell align="left">FINAL AMT</TableCell>
                <TableCell align="left">PAID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((row, index) => (
                <TableRow
                  key={row.tranid}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {index * 1 + 1}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {moment(row.trandate).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.username}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.pan}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {(row.amount * 1 + 15).toFixed(2)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {15}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {(row.amount * 1).toFixed(2)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {(row.amount * 0.01).toFixed(2)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {(row.amount * 1 - row.amount * 0.01).toFixed(2)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {parseInt(row.amount * 1 - row.amount * 0.01).toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
