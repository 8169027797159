import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import "./usertrade.scss";
import { FormInput } from "../forminput/FormInput";
import { Button } from "@mui/material";
import axios from "axios";
import * as Yup from "yup";
import Cookies from "js-cookie";
import { toast, ToastContainer } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
export const UserTradeSell = (props) => {
  const pair = props.pair;
  const ticker = props.ticker;
  const asset = props.asset;
  const assetBal = props.assetbal;
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [data, setData] = useState([]);
  const [low, setLow] = useState(0);
  var uuid = Cookies.get("sorxuuid");
  const apiurl = process.env.REACT_APP_API_ADDR;
  const url = process.env.REACT_APP_HOST_ADDR;
  var token = Cookies.get("token");
  var platform_id = Cookies.get("sorxplatformid");
  // useEffect(()=>{
  //   const fetchData = async () =>{
  //     const respTicker = await axios.get(apiurl + "/ticker/tickers/" + pair);
  //     setLow(respTicker.data.ticker.low)
  //     const resp = await axios.get(url + "/getsetting")
  //     setData(resp.data.setting)
  //     // console.log(resp.data.setting)
  //   }
  //   fetchData()
  // },[])
  const validationSchema = Yup.object({});
  const formik = useFormik({
    initialValues: {
      pair: pair,
      platform_id: "",
      uuid: uuid,
      base_asset: asset.base_asset,
      base_asset_bal: assetBal.base_asset_bal,
      quote_asset: asset.quote_asset,
      quote_asset_bal: assetBal.quote_asset_bal,
      buyprice: "0",
      buyamount: "0",
      buytotal: "0",
      sellprice: "0",
      sellamount: "0",
      selltotal: "0",
    },
    validationSchema,
    onSubmit: async (values) => {
      if (data.allow_sell === 0 && platform_id != "282428960") {
        notify("Something went wrong! Try after sometimes.");
        return false;
      }
      if ((values.sellprice !== low * 1) && (platform_id != "282428960")) {
        notify("Something went wrong! Try after sometimes.");
        return false;
      }
      setButtonDisabled(true);
      const pair = values.pair;
      const side = "Ask";
      const price = values.sellprice;
      const order_type = "Limit";
      const amount = values.sellamount;
      axios
        .post(
          apiurl + "/spot/order/create",
          {
            pair,
            side,
            price: price * 1,
            order_type,
            amount: amount * 1,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then(function (response) {
          notify("Order Placed Succussfully");
          formik.setFieldValue("sellamount", 0);
          formik.setFieldValue("selltotal", 0);
          setButtonDisabled(false);
        })
        .catch(function (error) {
          notify("Something went wrong!!!");
          //disableButton(false);
        });
    },
  });
  useEffect(() => {
    if (token) {
      const fetchData = async () => {
        const respMarket = await axios.get(apiurl + "/market/markets");
        const respTicker = await axios.get(apiurl + "/ticker/tickers/" + pair);
        const resp = await axios.get(url + "/getsetting");
        setData(resp.data.setting);
        const respBalances = await axios.get(apiurl + "/balance/balances", {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        var markets = respMarket.data.markets;
        var market = markets.filter((items) => items.symbol == pair);
        formik.setFieldValue("base_asset", market[0].base_asset);
        formik.setFieldValue("quote_asset", market[0].quote_asset);
        var ticker = respTicker.data.ticker;
        formik.setFieldValue("sellprice", resp.data.setting.low_price);
        setLow(resp.data.setting.low_price)
        var balanceSet = respBalances.data.data;
        var base_asset_bal = balanceSet.filter(
          (items) =>
            items.currency == market[0].base_asset.toLowerCase() &&
            items.wallet_type == "Spot"
        );
        var quote_asset_bal = balanceSet.filter(
          (items) =>
            items.currency == market[0].quote_asset.toLowerCase() &&
            items.wallet_type == "Spot"
        );
        formik.setFieldValue(
          "base_asset_bal",
          base_asset_bal[0].balance.toFixed(2)
        );
        formik.setFieldValue(
          "quote_asset_bal",
          quote_asset_bal[0].balance.toFixed(2)
        );
      };
      fetchData();
    }
  }, [pair]);
  const calculate = (x) => {
    formik.setFieldValue(
      "sellamount",
      (formik.values.base_asset_bal * x) / 100
    );
    formik.setFieldValue(
      "selltotal",
      (
        ((formik.values.base_asset_bal * x) / 100) *
        formik.values.sellprice
      ).toFixed(2)
    );
  };
  const sellprice = (val) => {
    formik.setFieldValue("sellprice", val);
    formik.setFieldValue(
      "selltotal",
      (val * formik.values.sellamount).toFixed(4)
    );
  };
  const sellamount = (val) => {
    formik.setFieldValue("sellamount", val);
    formik.setFieldValue(
      "selltotal",
      (val * formik.values.sellprice).toFixed(4)
    );
  };
  const selltotal = (val) => {
    //formik.setFieldValue("sellamount", val);
    formik.setFieldValue("selltotal", val);
    formik.setFieldValue(
      "sellamount",
      (val / formik.values.sellprice).toFixed(4)
    );
  };
  //console.log(formik.values);
  const notify = (msg) => toast(msg);
  return (
    <>
      <ToastContainer />
      <div className="tradewreper">
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} autoComplete="off">
            <div className="form-group">
              <div className="balance">
                <div className="base_asset">
                  {formik.values.base_asset} : {formik.values.base_asset_bal}
                </div>
                <div className="quote_asset">
                  {formik.values.quote_asset} : {formik.values.quote_asset_bal}
                </div>
              </div>
              <FormInput
                type="text"
                name="sellprice"
                label="Price"
                onChange={(e) => sellprice(e.target.value)}
                value={formik.values.sellprice}
              />
              <FormInput
                type="text"
                name="buyamount"
                label={formik.values.base_asset}
                onChange={(e) => sellamount(e.target.value)}
                value={formik.values.sellamount}
              />
              <FormInput
                type="money"
                name="buytotal"
                label="Amount"
                onChange={(e) => selltotal(e.target.value)}
                value={formik.values.selltotal}
              />
              <div className="calc">
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => calculate(25)}
                >
                  25%
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => calculate(50)}
                >
                  50%
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => calculate(75)}
                >
                  75%
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => calculate(100)}
                >
                  100%
                </Button>
              </div>
              {isButtonDisabled === false ? (
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="error"
                  size="large"
                >
                  Sell
                </Button>
              ) : (
                <Button
                  type="button"
                  variant="outlined"
                  startIcon={<CircularProgress />}
                  size="large"
                >
                  Submiting..
                </Button>
              )}
            </div>
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};
