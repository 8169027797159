import React, { useEffect, useState } from "react";
import "./pendingkycdetails.scss";
import { useNavigate, useParams } from "react-router-dom";
import FsLightbox from "fslightbox-react";
import axios from "axios";
import { Button, DialogContent, Divider, Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Draggable from 'react-draggable';
export const PendingKycDetails = () => {
  const Navigate = useNavigate();
  const [openpan, setOpenPan] = useState(false);
  const [openaadharf, setOpenAadharF] = useState(false);
  const [openaadharb, setOpenAadharB] = useState(false);
  const [openbank, setOpenBank] = useState(false);
  const [openselfy, setOpenSelfy] = useState(false);
  const [toggler, setToggler] = useState(false);
  const [data, setData] = useState({
    user_name: "NA",
    email:'',
    pan: "NA",
    aadhar: "NA",
    accno: "NA",
    branch: "NA",
    ifsc: "NA",
  });
  const { platformId } = useParams();
  const url = process.env.REACT_APP_HOST_ADDR;
  useEffect(() => {
    const fetchData = async () => {
      var profileData = await axios.post(url + "/user/getbankdetail", {
        platform_id: platformId,
      });
      setData(profileData.data.data);
    };
    fetchData();
  }, []);
  const panclose = () => {
    setOpenPan(false);
  };
  const selfyclose = () => {
    setOpenSelfy(false);
  };
  const aadhafclose = () => {
    setOpenAadharF(false);
  };
  const aadhabclose = () => {
    setOpenAadharB(false);
  };
  const bankclose = () => {
    setOpenBank(false);
  };
  const kycApprove = (email)=>{
    axios.post(url + "/admin/kycapprove",{
      email
    }).then(function(response){
      if(response.status == 200){
        Navigate("/admin/pendingkyc");
      }
    })
  }
  const kycReject = (email)=>{
    axios.post(url + "/admin/kycreject",{
      email
    }).then(function(response){
      if(response.status == 200){
        Navigate("/admin/pendingkyc");
      }
    })
  }
  return (
    <>
      <div className="pendingkycdetails">
        <Grid container spacing={2} className="topbuttons">
          <Grid item xs={12} md={2} className="leftside">
            <Button
              variant="contained"
              color="warning"
              fullWidth
              onClick={() => setOpenPan(true)}
            >
              PAN IMAGE.
            </Button>
          </Grid>
          <Grid item xs={12} md={2} className="leftside">
            <Button
              variant="contained"
              color="warning"
              fullWidth
              onClick={() => setOpenAadharF(true)}
            >
              AADHAR IMAGE.
            </Button>
          </Grid>
          <Grid item xs={12} md={2} className="leftside">
            <Button
              variant="contained"
              color="warning"
              fullWidth
              onClick={() => setOpenAadharB(true)}
            >
              AADHAR BACK.
            </Button>
          </Grid>
          <Grid item xs={12} md={2} className="leftside">
            <Button
              variant="contained"
              color="warning"
              fullWidth
              onClick={() => setOpenBank(true)}
            >
              CHEQUE.
            </Button>
          </Grid>
          <Grid item xs={12} md={2} className="leftside">
            <Button
              variant="contained"
              color="warning"
              fullWidth
              onClick={() => setOpenSelfy(true)}
            >
              SELFY IMAGE.
            </Button>
          </Grid>
          
          
        </Grid>
        <Grid container spacing={2} className="action">
          <Grid item xs={12} md={4} className="datas">
            <Button
              variant="contained"
              color="info"
              fullWidth
              onClick={() => kycApprove(data.email)}
            >
              APPROVE
            </Button>
          </Grid>
          <Grid item xs={12} md={4} className="datas">
          <Button
              variant="contained"
              color="error"
              fullWidth
              onClick={() => kycReject(data.email)}
            >
              REJECT
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} className="profile">
          <Grid item xs={12} md={4} className="datas">
            <div className="cont">
              <span>Name:</span>
              <span>{data.user_name}</span>
            </div>
            <div className="cont">
              <span>Name:</span>
              <span>{data.email}</span>
            </div>
            <div className="cont">
              <span>PAN:</span>
              <span>{data.pan}</span>
            </div>
            <div className="cont">
              <span>AADHAAR:</span>
              <span>{data.aadhar}</span>
            </div>

            <div className="cont">
              <span>ACC No:</span>
              <span>{data.accno}</span>
            </div>
            <div className="cont">
              <span>IFSC:</span>
              <span>{data.ifsc}</span>
            </div>
            <div className="cont">
              <span>Platform Id:</span>
              <span>{platformId}</span>
            </div>
          </Grid>
        </Grid>
      </div>
      <Dialog
        open={openselfy}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="mydlg"
        fullWidth
        onClose={selfyclose}
        maxWidth="sm"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ backgroundColor: "#990000", color: "#FFF" }}
        >
          SELFY
        </DialogTitle>
        <Divider />
        <DialogContent
          className="dlgcont"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <img
            src={`https://soarxupload.blr1.cdn.digitaloceanspaces.com/${platformId}/selfy.jpg`}
            alt=""
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openpan}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="mydlg"
        onClose={panclose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ backgroundColor: "#990000", color: "#FFF" }}
        >
          PAN: {data.pan}
        </DialogTitle>
        <Divider />
        <DialogContent
          className="dlgcont"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <img
            src={`https://soarxupload.blr1.cdn.digitaloceanspaces.com/${platformId}/pan.jpg`}
            alt=""
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openaadharf}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="mydlg"
        fullWidth
        onClose={aadhafclose}
        maxWidth="sm"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ backgroundColor: "#990000", color: "#FFF" }}
        >
          AADHAAR: {data.aadhar}
        </DialogTitle>
        <Divider />
        <DialogContent
          className="dlgcont"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <img
            src={`https://soarxupload.blr1.cdn.digitaloceanspaces.com/${platformId}/aadharfront.jpg`}
            alt=""
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openaadharb}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="mydlg"
        fullWidth
        onClose={aadhabclose}
        maxWidth="sm"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ backgroundColor: "#990000", color: "#FFF" }}
        >
          AADHAAR: {data.aadhar}
        </DialogTitle>
        <Divider />
        <DialogContent
          className="dlgcont"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <img
            src={`https://soarxupload.blr1.cdn.digitaloceanspaces.com/${platformId}/aadharback.jpg`}
            alt=""
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openbank}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="mydlg"
        fullWidth
        onClose={bankclose}
        maxWidth="sm"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ backgroundColor: "#990000", color: "#FFF" }}
        >
          BANK: {data.accno}/{data.branch}/{data.ifsc}
        </DialogTitle>
        <Divider />
        <DialogContent
          className="dlgcont"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <img
            src={`https://soarxupload.blr1.cdn.digitaloceanspaces.com/${platformId}/cheque.jpg`}
            alt=""
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
