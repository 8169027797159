import {
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { toast, ToastContainer } from "react-toastify";
import "./market.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
export const Market = () => {
  const [primecoin, setPrimecoin] = useState({});
  const url = process.env.REACT_APP_HOST_ADDR;
  useEffect(() => {
    const x = axios
      .get(url + "/allmarket", {})
      .then((response) => {
        console.log(response.data.market)
        setPrimecoin(response.data.market);
      })
      .catch(function (error) {
        console.log("first");
      });
  }, []);
  const notify = (msg) => toast(msg);
  const clickme = ()=>{
    notify("Please Sign to Trade.");
  }
  if (!primecoin) return false;
  console.log(primecoin.length);
  const rows = [];
  for (let i = 0; i < primecoin.length; i++) {
    rows.push(primecoin[i]);
  }
  return (
    <>
    <ToastContainer />
      <div className="market">
        <Grid container spacing={2} className="marketdata">
          <Grid item xs={12} md={8} className="leftside">
            <Card sx={{ marginBottom: 3, padding: 0 }}>
              <CardContent sx={{ padding: 1, marginBottom: 0 }}>
                <div className="title">
                  <h3>Markets</h3>
                  <p>Todays Exclusive Crypto Markets</p>
                </div>
              </CardContent>
            </Card>

            {/* <div className="hotmarket"></div> */}
            <div className="marketlist">
              <Card sx={{backgroundColor:"transparent"}}>
                <CardContent>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Price</TableCell>
                          <TableCell>Vol</TableCell>
                          <TableCell>Tarde</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow
                            key={row.market_id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row" className="imgrow">
                              <img src={row.logo} alt="" />
                              <div className="pair">
                                <div className="base">{row.base}/</div>
                                <div className="quote">{row.quote}</div>
                              </div>
                              
                            </TableCell>
                            <TableCell align="left">
                              {(row.last_price * 1).toFixed(2)}
                            </TableCell>
                            <TableCell align="center">
                              {(row.quote_volume * 1).toFixed(2)}
                            </TableCell>
                            <TableCell align="center">
                              <Button variant="outlined" size="small" onClick={()=>clickme()}>trade</Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>
      </div>
      {/* <Grid container spacing={2} className="market">
        <Grid item xs={12} md={12} className="leftside">
          <h2>Live Market Data!</h2>
          <div className="primecoins">
              <Link to="/user/trade/soarx_inr">
                <div className="datas" key='soarx'>
                  <div className="imgs">
                    <img src={"/images/SOARX.png"} alt="here" />
                    <h2>SOARX</h2>
                    <h3>SOARX COIN</h3>
                  </div>
                  <div className="vol">Volume</div>
                  <div className="high">High</div>
                  <div className="low">Low</div>
                  <div className="change">Change</div>
                  <div className="lastprice">Lastprice</div>
                </div>
              </Link>
              {rows.map((row) => (
                <Link to={'/user/trade/' + (row.market.replace("INR","")).toLowerCase() + "_inr"}>
                <div className="datas" key={row.market}>
                  <div className="imgs">
                    <img src={"/images/" + row.market.replace("INR","") + ".png"} alt="here" />
                    <h2>{row.market.replace("INR", "")}</h2>
                    <h3>{row.market.replace("INR", "")}</h3>
                  </div>
                  <div className="vol">{(row.volume * 1).toFixed(3)}</div>
                  <div className="high">{(row.high * 1).toFixed(3)}</div>
                  <div className="low">{(row.low * 1).toFixed(3)}</div>
                  <div className="change">
                    {(row.change_24_hour * 1).toFixed(2)}
                  </div>
                  <div className="lastprice">{(row.last_price * 1).toFixed(3)}</div>
                </div>
                </Link>
              ))}
            </div>
        </Grid>
      </Grid> */}
    </>
  );
};
