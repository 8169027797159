import React, { useState } from "react";
import "./docupload.scss";
import verrification from "../../../images/verrification.png";
import { Box, Card, CardContent, Grid, Paper } from "@mui/material";
import Cookies from "js-cookie";
import { StepThree } from "../../../components/kycdetail/StepThree";
import { StepFour } from "../../../components/kycdetail/StepFour";
import { StepFive } from "../../../components/kycdetail/StepFive";
import { StepCheque } from "../../../components/kycdetail/StepCheque";
import { StepSix } from "../../../components/kycdetail/StepSix";
import { StepSeven } from "../../../components/kycdetail/StepSeven";
export const DocUpload = () => {
  const uuid = Cookies.get("sorxuuid");
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState({
    panimage: null,
    aadharfrontimage: null,
    aadharback: null,
    selfy: null,
    cheque: null,
  });
  const handleNext = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const steps = [
    <StepThree next={handleNext} prev={handleBack} data={data} />,
    <StepFour next={handleNext} prev={handleBack} data={data} />,
    <StepFive next={handleNext} prev={handleBack} data={data} />,
    <StepCheque next={handleNext} prev={handleBack} data={data} />,
    <StepSix next={handleNext} prev={handleBack} data={data} />,
    <StepSeven next={handleNext} prev={handleBack} data={data} />,
  ];
  return(
    <>
      <Grid container spacing={2} className="kycdata">
        <Grid item xs={12} md={5} className="leftside">
          <Card sx={{backgroundColor:"transparent"}}>
            <CardContent sx={{backgroundColor:"transparent"}}>
              <Box sx={{ flexGrow: 1 }}>
                <Paper
                  square
                  elevation={0}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: 10,
                    pl: 0,
                    bgcolor: "background.default",
                  }}
                >
                  
                </Paper>
                <Box sx={{ width: "100%", p: 0 }}>{steps[activeStep]}</Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={7} className="rightside">
            <img src={verrification} alt="" />
        </Grid>
      </Grid>
    </>
  );
};
