import React, { useEffect, useState } from "react";
import "./oldbalance.scss";
import axios from "axios";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
export const OldBalance = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [userdata, setUserData] = useState({
    mobile: "",
    email: "",
    soarx: 0,
    inr: 0,
  });
  useEffect(() => {
    axios.get(url + "/admin/oldbalance").then(function (resp) {
      console.log(resp.data);
      setData(resp.data);
    });
  }, []);
  const checkOpen = (m, e, s, i) => {
    //alert(`${mobile} + ${email} + ${soarx} + ${inr}`)
    axios
      .post(url + "/admin/coinadding", {
        mobile: m,
        email: e,
        soarx: s,
        inr: i,
      })
      .then(function (resp) {
        window.location.reload(true)
      }).catch(function(error){
        console.log(error)
      });
  };
  const handleSubmit = () => {
    //console.log(activeEmail, reason)
    axios
      .post(url + "/admin/kycreject", {
        email: "activeEmail",
        reason: "",
      })
      .then(function (response) {
        if (response.status == 200) {
          //setKycOpen(false)
          window.location.reload(true);
        }
      });
    //notify("Reason Submitted. Please Refresh Page.");
  };
  return (
    <>
      <div className="oldbalance">
        <Card>
          <CardHeader title="Old Balance" />
          <CardContent>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">SL. No.</TableCell>
                    <TableCell align="center">Member Id</TableCell>
                    <TableCell align="center">Member Name</TableCell>
                    <TableCell align="center">Mobile</TableCell>
                    <TableCell align="center">Email</TableCell>
                    <TableCell align="center">SOARX</TableCell>
                    <TableCell align="center">INR</TableCell>
                    <TableCell align="center">Check</TableCell>
                    <TableCell align="center">Approve</TableCell>
                    <TableCell align="center">Reject</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, index) => (
                    <TableRow
                      key={row.cust_id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="left">{row.memberid}</TableCell>
                      <TableCell align="left">{row.mem_name}</TableCell>
                      <TableCell align="left">{row.mobile}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">{row.soarx}</TableCell>
                      <TableCell align="left">{row.inr}</TableCell>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          color="warning"
                          onClick={() =>
                            checkOpen(row.mobile, row.email, row.soarx, row.inr)
                          }
                        >
                          Check
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          color="info"
                          //onClick={() => kycApprove(row.email)}
                        >
                          Approve
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          color="error"
                          //onClick={() => kycReject(row.email)}
                        >
                          Reject
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </div>
      {/* <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="mydlg"
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title" sx={{ color: "#000" }}>
          KYC REJECT REASON
        </DialogTitle>
        <Divider />
        <DialogContent
          className="dlgcont"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: 1,
          }}
        >
          <input
            type="text"
            value={userdata.email}
            readOnly
            style={{ marginBottom: 3 }}
          />
          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            //onChange={(e) => handleChange(e.target.value)}
          ></textarea>
          <Button onClick={()=>handleSubmit}>SUBMIT</Button>
        </DialogContent>
      </Dialog> */}
    </>
  );
};
