import React, { useEffect, useState } from "react";
import "./bankdeposit.scss";
import axios from "axios";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import Cookies from "js-cookie";
export const BankDeposit = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const [pendingbank, setPendingBank] = useState({});
  useEffect(() => {
    axios.get(url + "/admin/pendingbank").then(function (resp) {
      console.log(resp.data);
      setPendingBank(resp.data);
    });
  }, []);
  const approveDeposit = (tranid, platform_id) => {
    axios
      .post(url + "/admin/approvebank", { tranid, platform_id })
      .then(function (resp) {
        window.location.reload(true)
      });
  };
  const getMobile = async (mob)=>{
    const resp = await axios.post(url + "/admin/getmobile",{mob})
    alert(resp.data.phone_number)
  }
  const rejectDeposit = async (tranid, platform_id) => {
    const resp = await axios.post(url + "/admin/rejeckbank",{tranid, platform_id})
    window.location.reload(true)
  };
  if (!pendingbank) return false;
  var rows = [];
  for (let i = 0; i < pendingbank.length; i++) {
    rows.push(pendingbank[i]);
  }
  return (
    <>
      <div className="bankdeposit">
        <Grid container spacing={2} className="adminboard">
          <Grid item xs={12} md={12} className="leftside">
            <Card sx={{ backgroundColor: "transparent", color: "#FFF" }}>
              <CardContent>
                <h2>Pending KYC</h2>
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">SL. No.</TableCell>
                        <TableCell align="center">Date</TableCell>
                        <TableCell align="center">Tran ID</TableCell>
                        <TableCell align="center">Mobile</TableCell>
                        <TableCell align="center">Name</TableCell>
                        <TableCell align="center">Amount</TableCell>
                        <TableCell align="center">Approve</TableCell>
                        <TableCell align="center">Reject</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, index) => (
                        <TableRow
                          key={row.uuid}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="left">
                            {moment(row.creation_date).format("YY-MMM-DD")}
                          </TableCell>
                          <TableCell align="left">{row.tranid}</TableCell>
                          <TableCell align="left"><Button onClick={()=>getMobile(row.platform_id)}>Click</Button></TableCell>
                          <TableCell align="left">{row.user_name}</TableCell>
                          <TableCell align="left">{row.amount}</TableCell>
                          <TableCell align="center">
                            <Button
                              variant="contained"
                              color="info"
                              onClick={() =>
                                approveDeposit(row.tranid, row.platform_id)
                              }
                            >
                              Approve
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() =>
                                rejectDeposit(row.tranid, row.platform_id)
                              }
                            >
                              Reject
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
