import React, { useEffect, useState } from "react";
import "./pendingkyc.scss";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import Cookies from "js-cookie";
import { toast, ToastContainer } from "react-toastify";
export const PendingKyc = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const apiurl = process.env.REACT_APP_API_ADDR;
  var token = Cookies.get("token");
  const [pendingkyc, setPendingkyc] = useState({});
  const [open, setOpen] = useState(false);
  const [kycopen, setKycOpen] = useState(false);
  const [reason, setReason] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [activeEmail, setActiveEmail] = useState("");
  const [data, setData] = useState({
    activedata: "",
    actvalue: "",
    actimage: "",
  });
  useEffect(() => {
    axios.get(url + "/admin/pendingkyc").then(function (resp) {
      setPendingkyc(resp.data);
    });
  }, []);
  const rejectKyc = async (orderId, xpair) => {
    const delresp = await axios.delete(
      apiurl + "/spot/order/" + xpair + "/" + orderId,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    console.log(delresp);
  };
  const checkOpen = (pltId, tocheck) => {
    setActiveId(pltId);
    const fetchData = async () => {
      var profileData = await axios.post(url + "/user/getbankdetail", {
        platform_id: pltId,
      });
      if (tocheck == "PAN") {
        setData({
          activedata: "PAN",
          actvalue: profileData.data.data.pan,
          actimage: `https://soarxupload.blr1.cdn.digitaloceanspaces.com/${pltId}/pan.jpg`,
        });
      } else if (tocheck == "AADHARF") {
        setData({
          activedata: "AADHAR FRONT",
          actvalue: profileData.data.data.aadhar,
          actimage: `https://soarxupload.blr1.cdn.digitaloceanspaces.com/${pltId}/aadharfront.jpg`,
        });
      } else if (tocheck == "AADHARB") {
        setData({
          activedata: "AADHAR BACK",
          actvalue: profileData.data.data.aadhar,
          actimage: `https://soarxupload.blr1.cdn.digitaloceanspaces.com/${pltId}/aadharback.jpg`,
        });
      } else if (tocheck == "CHEQUE") {
        setData({
          activedata: "BANK",
          actvalue: profileData.data.data.accno,
          actimage: `https://soarxupload.blr1.cdn.digitaloceanspaces.com/${pltId}/cheque.jpg`,
        });
      } else if (tocheck == "SELFY") {
        setData({
          activedata: "SELFY",
          actvalue: profileData.data.data.mobile,
          actimage: `https://soarxupload.blr1.cdn.digitaloceanspaces.com/${pltId}/selfy.jpg`,
        });
      }

      console.log(profileData.data.data);
      //setData(profileData.data.data);
    };
    fetchData();
    setOpen(true);
  };
  const kycApprove = (email) => {
    axios
      .post(url + "/admin/kycapprove", {
        email,
      })
      .then(function (response) {
        window.location.reload(true)
      });
  };
  const kycReject = (email) => {
    setActiveEmail(email);
    setKycOpen(true);
    // axios.post(url + "/admin/kycreject",{
    //   email
    // }).then(function(response){
    //   if(response.status == 200){
    //     //Navigate("/admin/pendingkyc");
    //   }
    // })
  };
  const handleChange = (val) => {
    //console.log(evet.target.value)
    setReason(val);
    console.log(reason);
  };
  const handleSubmit = ()=>{
    console.log(activeEmail, reason)
    axios
      .post(url + "/admin/kycreject", {
        email: activeEmail,
        reason
      })
      .then(function (response) {
        if (response.status == 200) {
          setKycOpen(false)
          window.location.reload(true)
        }
      });
    notify("Reason Submitted. Please Refresh Page.");
  }
  const notify = (msg) => toast(msg);
  if (!pendingkyc) return false;
  var rows = [];
  for (let i = 0; i < pendingkyc.length; i++) {
    rows.push(pendingkyc[i]);
  }
  return (
    <>
    <ToastContainer />
      <div className="pendingkyc">
        <Grid container spacing={2} className="actorder">
          <Grid item xs={12} md={12} className="leftside">
            <Card sx={{ backgroundColor: "transparent", color: "#FFF" }}>
              <CardContent>
                <h2>Pending KYC</h2>
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">SL. No.</TableCell>
                        <TableCell align="center">Date</TableCell>
                        <TableCell align="center">Platform Id</TableCell>
                        <TableCell align="center">Full Name</TableCell>
                        <TableCell align="center">Check</TableCell>
                        <TableCell align="center">Approve</TableCell>
                        <TableCell align="center">Reject</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, index) => (
                        <TableRow
                          key={row.uuid}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="left">
                            {moment(row.creation_date).format("YY-MMM-DD")}
                          </TableCell>
                          <TableCell align="left">{row.platform_id}</TableCell>
                          <TableCell align="left">{row.first_name}</TableCell>
                          <TableCell align="center">
                            <Button
                              variant="contained"
                              color="warning"
                              onClick={() => checkOpen(row.platform_id, "PAN")}
                            >
                              Check
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              variant="contained"
                              color="info"
                              onClick={() => kycApprove(row.email)}
                            >
                              Approve
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => kycReject(row.email)}
                            >
                              Reject
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="mydlg"
          onClose={() => setOpen(false)}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{ backgroundColor: "#990000", color: "#FFF" }}
          >
            {data.activedata} : {data.actvalue}
          </DialogTitle>
          <Divider />
          <DialogContent
            className="dlgcont"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: 1,
            }}
          >
            <img src={data.actimage} alt="" />
            <Divider />
            <div
              className="but"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button onClick={() => checkOpen(activeId, "PAN")}>
                PAN CADR
              </Button>
              <Button onClick={() => checkOpen(activeId, "AADHARF")}>
                AADHAR FRONT
              </Button>
              <Button onClick={() => checkOpen(activeId, "AADHARB")}>
                AADHAR BACK
              </Button>
              <Button onClick={() => checkOpen(activeId, "CHEQUE")}>
                CHEQUE
              </Button>
              <Button onClick={() => checkOpen(activeId, "SELFY")}>
                SELFY
              </Button>
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={kycopen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="mydlg"
          onClose={() => setKycOpen(false)}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title" sx={{ color: "#000" }}>
            KYC REJECT REASON
          </DialogTitle>
          <Divider />
          <DialogContent
            className="dlgcont"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: 1,
            }}
          >
            <input
              type="text"
              value={activeEmail}
              readOnly
              style={{ marginBottom: 3 }}
            />
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              onChange={(e) => handleChange(e.target.value)}
            ></textarea>
            <Button onClick={handleSubmit}>SUBMIT</Button>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};
