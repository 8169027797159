import { Alert, Button, Card, CardContent, Grid } from "@mui/material";
import "./forgetpassword.scss";
import SendIcon from "@mui/icons-material/Send";
import CircularProgress from "@mui/material/CircularProgress";
import { Form, FormikProvider, useFormik } from "formik";
import { FormInput } from "../../../components/forminput/FormInput";
import { Link, useNavigate } from "react-router-dom";
import verrification from "../../../images/verrification.png";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import axios from "axios";
import * as Yup from "yup";
export const ForgetPassword = () => {
  const Navigate = useNavigate();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [isotpButtonDisabled, setotpButtonDisabled] = useState(false);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apiurl = process.env.REACT_APP_API_ADDR;  
  const validationSchema = Yup.object({
    email: Yup.string().email(),
    otp: Yup.number("Invalid OTP, Please Check!!!").required(
      "Field can not be empty."
    ),
    password: Yup.string()
      .min(8, "Minimum 8 Charecter Required")
      .matches(/[a-zA-Z]/, "Password can only contain Latin letters.")
      .required("Invalid Password."),
    cpassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Password not matched"
    ),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      otp: "",
      password: '',
      cpassword: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      setButtonDisabled(true);
      const { email, password, otp } = values;
      axios
        .post(apiurl + "/auth/reset-password/complete", {
          method: "Email",
          data: email,
          new_password: password,
          otp: otp
        })
        .then(function (response) {
          if (response.status === 200) {
            notify("Password Changed Succussfully. Please navigate to Log In"); 
            setButtonDisabled(false)
          }else{
            setButtonDisabled(false)
            notify("Something went wrong. Try after sometimes!");
          }
        })
        .catch(function (error) {
          notify("Something went wrong. Try after sometimes!");
          setButtonDisabled(false);
        });
    },
  });
  const disableButton = () => {
    setButtonDisabled(true);
  };
  const sendOtp = () => {
    setotpButtonDisabled(true);
    const email = formik.values.email
    axios
      .post(apiurl + "/auth/reset-password", {
        method: "Email",
        data: email,
      })
      .then(function (response) {
        notify("Email OTP!!! OTP has sent to your Registered Email");
      })
      .catch(function (error) {
        notify("Sorry!!! Something went wrong.");
        setotpButtonDisabled(false);
      });
  };
  const notify = (msg) => toast(msg);
  return (
    <>
    <ToastContainer />
      <Grid container spacing={2} className="forgetpass">
        <Grid item xs={12} md={6} className="leftside">
          <Card sx={{ backgroundColor: "transparent" }}>
            <CardContent>
              <Alert sx={{marginBottom:3}} severity="warning">Forgot Password? Reset It.</Alert>
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit}>
                  <div className="form-group">
                    <FormInput
                      type="email"
                      name="email"
                      label="Email Address"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                    <Button
                      type="button"
                      variant="outlined"
                      disabled={isotpButtonDisabled}
                      startIcon={<SendIcon />}
                      color="warning"
                      onClick={sendOtp}
                    >
                      Get OTP
                    </Button>
                    <FormInput
                      type="text"
                      name="otp"
                      label="OTP"
                      onChange={formik.handleChange}
                      value={formik.values.otp}
                    />
                    <FormInput
                      type="password"
                      name="password"
                      label="Password"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                    />
                    <FormInput
                      type="password"
                      name="cpassword"
                      label="Confirm Password"
                      onChange={formik.handleChange}
                      value={formik.values.cpassword}
                    />
                    {isButtonDisabled === false ? (
                      <Button type="submit" variant="outlined" size="large">
                        Submit
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        variant="outlined"
                        startIcon={<CircularProgress />}
                        size="large"
                      >
                        Submiting..
                      </Button>
                    )}
                  </div>
                </Form>
              </FormikProvider>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} className="rightside">
          <img src={verrification} alt="" />
        </Grid>
      </Grid>
    </>
  );
};
