import React from "react";
import "./displaymsg.scss";
import { Link, useParams } from "react-router-dom";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import { Button, Divider, Grid } from "@mui/material";
export const Displaymsg = () => {
  let { id, cont, backto, dashboard } = useParams();
  var backtolink = "";
  if (dashboard == 1) {
    dashboard = "/";
    backtolink = "/" + backto;
  } else if (dashboard == 2) {
    dashboard = "/user";
    if(backto=='nowhere'){
      backtolink = "/user/";
    }else{
      backtolink = "/user/" + backto;
    }
    
  }
  return (
    <>
      <Grid container spacing={2} className="dspmsg">
        <Grid item xs={12} md={7} className="leftside">
          <div className="wraper">
            <div className="head">
              <span>
                <EmojiEmotionsIcon fontSize="large" sx={{ color: "#81c784" }} />
                <span>{id}</span>
              </span>
              <Divider />
            </div>
            <div className="body">{cont}</div>
            <div className="footers">
              <Button
                href={dashboard}
                size="small"
                color="info"
                variant="contained"
              >
                Dashboard
              </Button>
              <Button
                href={backtolink}
                size="small"
                color="success"
                variant="contained"
              >
                {backto}
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
