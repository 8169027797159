import { Alert, Divider, Grid } from "@mui/material";
import React from "react";
import "./footer.scss"
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <>
    <div className="footer">
    <Grid container spacing={5} className="footerdata">
        <Grid item xs={12} md={3} className="community">
            <h2>Social Link</h2>
            <Divider />
            <div className="icons">
              <TelegramIcon />
              <TwitterIcon />
              <InstagramIcon />
              <FacebookIcon />
              <YouTubeIcon />
            </div>
            <div className="cont">
              <Link to='mailto:support@soarxbits.com'>support@soarxbits.com</Link>
            </div>
        </Grid>
        <Grid item xs={12} md={3} className="footaboutus">
            <h2>About Us</h2>
            <Divider />
            <ul className="list">
              <li><Link to="/aboutus">About</Link></li>
              <li><Link to="/career">Career</Link></li>
              <li><Link to="legal">Legal</Link></li>
              <li><Link to="terms">Terms</Link></li>
              <li><Link to="privacy">Privecy Policy</Link></li>
              <li><Link to="blogs">Blog</Link></li>
            </ul>
        </Grid>
        <Grid item xs={12} md={3} className="services">
        <h2>Services</h2>
            <Divider />
            <ul className="list">
              <li><Link to="/buysellcrypto">Buy/Sell Crypto</Link></li>
              <li><Link to="/taxation">Tax</Link></li>
              <li><Link to="/research">Research</Link></li>
              <li><Link to="/charity">Charity</Link></li>
            </ul>
            <Divider />
            <ul className="list">
              <li><Link to="/coinlisting">Coin/Token Listing</Link></li>
              <li><Link to="/affiliate">Affiliate</Link></li>
              <li><Link to="/referral">Referral</Link></li>
              <li><Link to="/gift">Gift</Link></li>
            </ul>
        </Grid>
        <Grid item xs={12} md={3} className="others">
        <h2>Others</h2>
            <Divider />
            <ul className="list">
              <li><Link to="#">24/7 Chat Support</Link></li>
              <li><Link to="#">Support Center</Link></li>
              <li><Link to="#">Fees</Link></li>
              <li><Link to="#">APIs</Link></li>
              <li><Link to="#">Product Feedback & Suggestions</Link></li>
            </ul>
            
        </Grid>
      </Grid>
      <div className="copyright">
        all right reserve soarxbits© 2024
      </div>
    </div>
      
      
    </>
  );
};
