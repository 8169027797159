import React, { useEffect, useState } from "react";
import "./login.scss";
import { Field, Form, FormikProvider, useFormik } from "formik";
import { FormInput } from "../../../components/forminput/FormInput";
import CircularProgress from "@mui/material/CircularProgress";
import GoogleIcon from "@mui/icons-material/Google";
import AppleIcon from "@mui/icons-material/Apple";
import { Link, useNavigate } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import login from "../../../images/login.png";
import Cookies from "js-cookie";
import axios from "axios";
import * as Yup from "yup";
export const Login = () => {
  const Navigate = useNavigate();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [isotpButtonDisabled, setotpButtonDisabled] = useState(false);
  const [authChecked, setAuthCheck] = useState(true);
  const [loginType, setLoginType] = useState("TOTP");
  const [data, setData] = useState([]);
  const apiurl = process.env.REACT_APP_API_ADDR;
  const url = process.env.REACT_APP_HOST_ADDR;
  const validationSchema = Yup.object({
    email: Yup.mixed("Invalid OTP, Please Check!!!").required(
      "Field can not be empty."
    ),
    password: Yup.string()
      .min(8, "Minimum 8 Charecter Required")
      .matches(/[a-zA-Z]/, "Password can only contain Latin letters.")
      .required("Invalid Password."),
  });
  useEffect(()=>{
    const fetchData = async () =>{
      const resp = await axios.get(url + "/getsetting")
      setData(resp.data.setting)
    }
    fetchData()
  },[url])
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      otp: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      if(data.allow_login===0){
        notify("Login is temporarily off!");
        return false
      }
      disableButton();
      var mfa = "TOTP";
      if (authChecked === false) {
        mfa = "Email";
      }
      axios
        .post(apiurl + "/auth/login", {
          method: "Email",
          data: formik.values.email,
          password: formik.values.password,
          mfa: [
            {
              method: mfa,
              data: formik.values.otp,
            },
          ],
        })
        .then(function (response) {
          if (response.status === 200) {
            var in30Minutes = new Date(new Date().getTime() + 50 * 60 * 1000);
            Cookies.set("token", response.data.token, {
              expires: in30Minutes,
            });
            localStorage.setItem("srxactiveemail", formik.values.email);
            Navigate("/user");
          }
          // if (response.status == 201) {
          //   Navigate("/displaymsg/Success/Account Created Successfully. Please Proceed to Login/login/1");
          // }
        })
        .catch(function (error) {
          notify("Userid or password not matched!");
          setButtonDisabled(false);
          console.log(error.response.data.status);
        });
    },
  });
  const sendOtp = () => {
    setotpButtonDisabled(true);
    var mobile = formik.values.mobile;
    axios
      .post(apiurl + "/auth/request-otp", {
        method: "Email",
        data: formik.values.email,
        password: formik.values.password,
        otp_method: "Email",
      })
      .then(function (response) {
        notify("Log in OTP Sent to your Email Address.");
      })
      .catch(function (error) {
        notify("Sorry!!! Something went wrong.");
        setButtonDisabled(false);
      });
  };
  const disableButton = () => {
    setButtonDisabled(true);
  };
  const handleChange = (event) => {
    setAuthCheck(event.target.checked);
  };
  const notify = (msg) => toast(msg);
  return (
    <>
      <ToastContainer />
      <Grid container spacing={2} className="login">
        <Grid item xs={12} md={6} className="leftside">
          <Card sx={{ backgroundColor: "transparent" }}>
            <CardContent>
              <h2>Welcome back to soarxbits!</h2>
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="form-group">
                    <div>Use Email Address: </div>
                    <FormInput
                      type="email"
                      name="email"
                      label="Email Address"
                      autoFocus
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                    <FormInput
                      type="password"
                      name="password"
                      label="Password"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Use Auth Code"
                      onChange={handleChange}
                      checked={authChecked}
                    />
                    {!authChecked && (
                      <>
                        {isotpButtonDisabled == true ? (
                          <Button
                            type="button"
                            variant="outlined"
                            startIcon={<SendIcon />}
                            color="warning"
                          >
                            OTP SENT
                          </Button>
                        ) : (
                          <Button
                            type="button"
                            variant="outlined"
                            disabled={isotpButtonDisabled}
                            startIcon={<SendIcon />}
                            color="warning"
                            onClick={sendOtp}
                          >
                            Get Email OTP
                          </Button>
                        )}
                      </>
                    )}

                    <FormInput
                      type="text"
                      name="otp"
                      label="Auth Code / OTP"
                      onChange={formik.handleChange}
                      value={formik.values.otp}
                    />
                    <div>
                      Forget Password?{" "}
                      <Link to="/forgetpassword" className="link">
                        Reset
                      </Link>
                    </div>
                    {isButtonDisabled === false ? (
                      <Button type="submit" variant="outlined" size="large">
                        Submit
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        variant="outlined"
                        startIcon={<CircularProgress />}
                        size="large"
                      >
                        Submitting..
                      </Button>
                    )}
                  </div>
                </Form>
              </FormikProvider>
            </CardContent>
          </Card>

          <Divider>OR</Divider>
          <Button variant="outlined" color="warning" startIcon={<GoogleIcon />}>
            Continue with Google
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<AppleIcon />}
          >
            Continue with Apple
          </Button>
          <p>
            Dont have account?{" "}
            <Link to="/signup" className="link">
              Sign Up
            </Link>{" "}
          </p>
        </Grid>
        <Grid item xs={12} md={6} className="rightside">
          <img src={login} alt="" />
        </Grid>
      </Grid>
    </>
  );
};
