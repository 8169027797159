import React, { useEffect, useState } from "react";
import "./individualsignup.scss";
import { Form, FormikProvider, useFormik } from "formik";
import { FormInput } from "../../../components/forminput/FormInput";
import { Box, Button, Card, CardContent, Divider, Grid } from "@mui/material";
import signup from "../../../images/signup.png";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const IndividualSignup = () => {
  const Navigate = useNavigate();
  const url = process.env.REACT_APP_HOST_ADDR;
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [data, setData] = useState([]);
  useEffect(()=>{
    const fetchData = async () =>{
      const resp = await axios.get(url + "/getsetting")
      setData(resp.data.setting)
    }
    fetchData()
  },[])
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid Email Address Found.")
      .required("Please Enter Valid Email"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      
      if(data.allow_signup==0){
        notify("Sign Up is temporarily off!");
        return false
      }
      disableButton(true);
      axios
        .post(url + "/indsignup", {
          values,
        })
        .then(function (response) {
          if (response.status == 200) {
            localStorage.setItem("soarxemail", response.data.email);
            Navigate("/indsignupemail");
          } else {
            notify("User exist!!! Try using other email.");
            disableButton(false);
          }
        })
        .catch(function (error) {
          notify("User exist!!! Try using other email.");
          disableButton(false);
        });
    },
  });
  const disableButton = (x) => {
    setButtonDisabled(x);
  };
  const notify = (msg) => toast(msg);
  return (
    <>
      <ToastContainer />
      <Grid container spacing={2} className="indsignup">
        <Grid item xs={12} md={6} className="leftside">
          <Card sx={{ backgroundColor: "transparent" }}>
            <CardContent>
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                  <h2>Create new account! Email</h2>
                  <Divider />
                  <div className="form-group">
                    <FormInput
                      type="email"
                      name="email"
                      label="Enter Email Address to Signup"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                  </div>
                  <div className="msg">
                    <p>By creating an account,</p>
                    <p>
                      I agree to soarxbits's{" "}
                      <Link to="#">Terms of Service</Link> and{" "}
                      <Link to="#">Privacy Policy</Link>.
                    </p>
                  </div>

                  {isButtonDisabled === false ? (
                    <Button type="submit" variant="outlined" size="large">
                      Submit
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      variant="outlined"
                      startIcon={<CircularProgress />}
                      size="large"
                    >
                      Submiting..
                    </Button>
                  )}
                  <p>
                    Already have account? <Link to="/login">Log In</Link>{" "}
                  </p>
                </Form>
              </FormikProvider>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} className="rightside">
          <Box sx={{ width: "100%" }}>
            <img src={signup} alt="" />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
