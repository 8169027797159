import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import { Home } from "./pages/homepages/home/Home";
import { HomeAppBar } from "./components/homeappbar/HomeAppBar";
import { Footer } from "./components/footer/Footer";
import "./style.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import { Login } from "./pages/homepages/login/Login";
import { Signup } from "./pages/homepages/signup/Signup";
import { IndividualSignup } from "./pages/homepages/individualsignup/IndividualSignup";
import { IndSignupEmail } from "./pages/homepages/indsignupemail/IndSignupEmail";
import { ForgetPassword } from "./pages/homepages/forgetpassword/ForgetPassword";
import { Displaymsg } from "./pages/displaymsg/Displaymsg";
import { Market } from "./pages/homepages/market/Market";
import { Aboutus } from "./pages/homepages/aboutus/Aboutus";
import { Usernav } from "./components/usernav/Usernav";
import { Dashboard } from "./pages/users/dashboard/Dashboard";
import { Userfooter } from "./components/userfooter/Userfooter";
import { Profile } from "./pages/users/profile/Profile";
import { MobileVerify } from "./pages/users/mobileverify/MobileVerify";
import { KycData } from "./pages/users/kycdata/KycData";
import { PanUpload } from "./pages/users/panupload/PanUpload";
import { InrDeposit } from "./pages/users/inrdeposit/InrDeposit";
import { InrWithdraw } from "./pages/users/inrwithdraw/InrWithdraw";
import { CryptoWithdraw } from "./pages/users/cryptowithdraw/CryptoWithdraw";
import { CryptoDeposit } from "./pages/users/cryptodeposit/CryptoDeposit";
import { Orders } from "./pages/users/orders/Orders";
import { Trade } from "./pages/users/trade/Trade";
import { UserMarket } from "./pages/users/usermarket/UserMarket";

import { AdminLogIn } from "./pages/admin/adminlogin/AdminLogIn";
import { AdminDashBoard } from "./pages/admin/admindashboard/AdminDashBoard";
import { AdminNav } from "./components/adminnav/AdminNav";
import { TickerUpdate } from "./pages/admin/tickerupdate/TickerUpdate";
import { PendingKyc } from "./pages/admin/pendingkyc/PendingKyc";
import { PendingKycDetails } from "./pages/admin/pendingkycdetails/PendingKycDetails";
import { DocUpload } from "./pages/users/docupload/DocUpload";
import { BankDeposit } from "./pages/admin/bankdeposit/BankDeposit";
import { AdminLogingIn } from "./pages/homepages/adminloginin/AdminLogingIn";
import { OldBalance } from "./pages/admin/oldbalance/OldBalance";
import { BankWithdraw } from "./pages/admin/bankwithdraw/BankWithdraw";
import { UserGst } from "./pages/admin/usergst/UserGst";
import { OfficeLogin } from "./pages/homepages/officelogin/OfficeLogin";
import { OfficeDashboard } from "./pages/office/OfficeDashboard";
import { TdsReport } from "./pages/office/TdsReport";
import { UserTds } from "./pages/admin/usertds/UserTds";
import { BuySell } from "./pages/users/buysell/BuySell";
function App() {
  const { darkMode } = useContext(DarkModeContext);
  const HomeLayout = () => {
    return (
      <div className={`theme-${darkMode ? "dark" : "light"}`}>
        <HomeAppBar />
        <Outlet />
        <Footer />
      </div>
    );
  };
  const UserLayout = () => {
    return (
      <div className={`theme-${darkMode ? "dark" : "light"}`}>
        <Usernav />
        <Outlet />
        <Userfooter />
      </div>
    );
  };
  const AdminLayout = () => {
    return (
      <div className={`theme-${darkMode ? "dark" : "light"}`}>
        <AdminNav />
        <Outlet />
        <Footer />
      </div>
    );
  };
  const OfficeLayout = () => {
    return (
      <div className={`theme-${darkMode ? "dark" : "light"}`}>
        <AdminNav />
        <Outlet />
        <Footer />
      </div>
    );
  };
  const router = createBrowserRouter([
    {
      path: "/user",
      element: <UserLayout />,
      children: [
        { path: "/user", element: <Dashboard /> },
        { path: "/user/profile", element: <Profile /> },
        { path: "/user/mobileverify", element: <MobileVerify /> },
        { path: "/user/kycdata", element: <KycData /> },
        { path: "/user/docupload", element: <DocUpload /> },
        { path: "/user/panupload", element: <PanUpload /> },
        { path: "/user/inrdeposit", element: <InrDeposit /> },
        { path: "/user/inrwithdraw", element: <InrWithdraw /> },
        { path: "/user/cryptowithdraw", element: <CryptoWithdraw /> },
        { path: "/user/cryptodeposit", element: <CryptoDeposit /> },
        { path: "/user/orders", element: <Orders /> },
        { path: "/user/trade", element: <Trade /> },
        { path: "/user/market", element: <UserMarket /> },
        { path: "/user/buysell", element: <BuySell /> },
      ],
    },
    {
      path: "/",
      element: <HomeLayout />,
      children: [
        { path: "/", element: <Home /> },
        { path: "/login", element: <Login /> },
        { path: "/market", element: <Market /> },
        { path: "/signup", element: <Signup /> },
        { path: "/indsignup", element: <IndividualSignup /> },
        { path: "/forgetpassword", element: <ForgetPassword /> },
        { path: "/indsignupemail", element: <IndSignupEmail /> },
        {
          path: "/displaymsg/:id/:cont/:backto/:dashboard",
          element: <Displaymsg />,
        },
        { path: "/aboutus", element: <Aboutus /> },
        { path: "/adminlogin", element: <AdminLogingIn /> },
        { path: "/officelogin", element: <OfficeLogin /> },
      ],
    },
    {
      path: "/admin",
      element: <AdminLayout />,
      children: [
        { path: "/admin/dashboard", element: <AdminDashBoard /> },
        { path: "/admin/tickerupdate", element: <TickerUpdate /> },
        { path: "/admin/pendingkyc", element: <PendingKyc /> },
        { path: "/admin/pendingkycdetails/:platformId", element: <PendingKycDetails /> },
        { path: "/admin/bankdeposit", element: <BankDeposit /> },
        { path: "/admin/bankwithdraw", element: <BankWithdraw /> },
        { path: "/admin/oldbalance", element: <OldBalance /> },
        { path: "/admin/usergst", element: <UserGst /> },
        { path: "/admin/usertds", element: <UserTds /> },
      ],
    },
    {
      path: "/office",
      element: <OfficeLayout />,
      children: [
        { path: "/office/", element: <OfficeDashboard /> },
        { path: "/office/tdsreport", element: <TdsReport /> },
        // { path: "/admin/tickerupdate", element: <TickerUpdate /> },
        // { path: "/admin/pendingkyc", element: <PendingKyc /> },
        // { path: "/admin/pendingkycdetails/:platformId", element: <PendingKycDetails /> },
        // { path: "/admin/bankdeposit", element: <BankDeposit /> },
        // { path: "/admin/bankwithdraw", element: <BankWithdraw /> },
        // { path: "/admin/oldbalance", element: <OldBalance /> },
        // { path: "/admin/usergst", element: <UserGst /> },
      ],
    },
  ]);
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
