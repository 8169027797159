import React, { useState } from "react";
import "./kycdata.scss";
import verrification from "../../../images/verrification.png";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Paper,
} from "@mui/material";
import Cookies from "js-cookie";
import { StepOne } from "../../../components/kycdetail/StepOne";
import { StepAadhar } from "../../../components/kycdetail/StepAadhar";
import { StepTwo } from "../../../components/kycdetail/StepTwo";
import { FinalDisplay } from "../../../components/kycdetail/FinalDisplay";

export const KycData = () => {
  const uuid = Cookies.get("sorxuuid");
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState({
    uuid: uuid,
    fullname: "",
    panno: "",
    aadhar: "",
    state:'',
    accno: "",
    ifsc: "",
    bankname: "",
    branch: "",
    bankstate:'',
    city:'',
    panimage: null,
    aadharfrontimage: null,
    aadharback: null,
    selfy: null,
    cheque:null,
    pandata: "",
    aadharfrontdata:'',
    aadharbackdata:'',
    selfydata:'',
    chequedata:'',
    panfetch:0,
    aadharfetch:0,
    bankfetch:0,
  });
  const handleNext = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const steps = [
    <StepOne next={handleNext} data={data} />,
    <StepAadhar next={handleNext} prev={handleBack} data={data} />,
    <StepTwo next={handleNext} prev={handleBack} data={data} />,
    <FinalDisplay next={handleNext} prev={handleBack} data={data} />,
    // <StepThree next={handleNext} prev={handleBack} data={data} />,
    // <StepFour next={handleNext} prev={handleBack} data={data} />,
    // <StepFive next={handleNext} prev={handleBack} data={data} />,
    // <StepCheque next={handleNext} prev={handleBack} data={data} />,
    // <StepSix next={handleNext} prev={handleBack} data={data} />,
    // <StepSeven next={handleNext} prev={handleBack} data={data} />,
  ];
  return (
    <>
      <Grid container spacing={2} className="kycdata">
        <Grid item xs={12} md={5} className="leftside">
          <Card sx={{backgroundColor:"transparent"}}>
            <CardContent sx={{backgroundColor:"transparent"}}>
              <Box sx={{ flexGrow: 1 }}>
                <Paper
                  square
                  elevation={0}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: 10,
                    pl: 0,
                    bgcolor: "background.default",
                  }}
                >
                  
                </Paper>
                <Box sx={{ width: "100%", p: 0 }}>{steps[activeStep]}</Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={7} className="rightside">
            <img src={verrification} alt="" />
        </Grid>
      </Grid>
    </>
  );
};
