import React, { useEffect, useState } from "react";
import "./inrdeposit.scss";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { FormInput } from "../../../components/forminput/FormInput";
import CircularProgress from "@mui/material/CircularProgress";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CopyAllOutlinedIcon from "@mui/icons-material/CopyAllOutlined";
import axios from "axios";
import * as Yup from "yup";
import Cookies from "js-cookie";
import { toast, ToastContainer } from "react-toastify";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { useNavigate } from "react-router-dom";
export const InrDeposit = () => {
  const Navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [inrList, setInrList] = useState({});
  const url = process.env.REACT_APP_HOST_ADDR;
  const email = localStorage.getItem("srxactiveemail");
  var platform_id = Cookies.get("sorxplatformid");
  var uuid = Cookies.get('sorxuuid')
  var token = Cookies.get("token");
  const apiurl = process.env.REACT_APP_API_ADDR;
  useEffect(() => {
    if (token) {
      axios
        .get(apiurl + "/user/user/data", {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            formik.setFieldValue("username", response.data.first_name)
        
          }
        })
        .catch(function (error) {
          console.log(error)
        });
    }
  }, []);
  console.log(email)
  const validationSchema = Yup.object({
    tranid: Yup.string("Invalid Transection Number, Please Check!!!").required(
      "Field can not be empty."
    ),
    amount: Yup.number().min(200, "Minumum 200 is allowed"),
  });
  const formik = useFormik({
    initialValues: {
      username: "",
      tranid: "",
      amount: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      disableButton(true);
      const {username, tranid, amount } = values;
      axios
        .post(url + "/user/inrdeposit", {
          platform_id,
          uuid,
          email,
          username,
          tranid,
          amount,
        })
        .then(function (response) {
          if (response.status == 200) {
            Navigate("/displaymsg/Success/You request submitted Successfully./inrdeposit/2");
          } else if (response.status == 201) {
            notify("Something went wrong! Check Tran Id and Amount.");
            disableButton(false);
          }
        })
        .catch(function (error) {
          notify("Something went wrong!!!");
          disableButton(false);
        });
    },
  });
  const disableButton = (x) => {
    setButtonDisabled(x);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const copyText = (x) => {
    navigator.clipboard.writeText(x);
  };
  const notify = (msg) => toast(msg);
  useEffect(() => {
    axios
      .post(url + "/user/getinrdeposit", {
        platform_id,
      })
      .then(function (response) {
        setInrList(response.data.data);
        //console.log(response.data.data)
      })
      .catch(function (error) {});
  }, []);
  const columns = [
    {
      field: "id",
      headerName: "Tran Id",
      width: 150,
      editable: true,
    },
    { field: "trandate", headerName: "Date", width: 90 },

    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      editable: true,
    },
    {
      field: "status",
      headerName: "Status",
      type: "number",
      width: 110,
      editable: true,
    },
  ];
  if (!inrList) return false;
  const rows = [];
  for (let i = 0; i < inrList.length; i++) {
    var str = {
      id: inrList[i].tranid,
      trandate: inrList[i].tran_date,
      amount: inrList[i].amount,
      status: inrList[i].status,
    };
    rows.push(str);
  }
  return (
    <>
      <ToastContainer />
      <div className="inrdeposit">
        <Grid container spacing={2} className="inrcontent">
          <Grid item xs={12} md={6} className="leftside">
            <Card sx={{ backgroundColor: "transparent" }}>
              <CardContent>
              <div className="depositadd">
                  <CheckCircleOutlineOutlinedIcon />
                  <p>Deposit on Official Account Only!!!</p>
                  <IconButton aria-label="delete" onClick={handleClickOpen}>
                    <ManageSearchOutlinedIcon />
                  </IconButton>
                </div>
                <Alert severity="error" sx={{ marginTop: 2, marginBottom: 2 }}>
                  UPI Deposit is not allowed!!!
                </Alert>
                <h2>Enter Transaction Details:</h2>
                <FormikProvider value={formik}>
                  <Form onSubmit={formik.handleSubmit} autoComplete="off">
                    <div className="form-group">
                      <div>Do not provide duplicate Tran Id.</div>
                      <FormInput
                        type="text"
                        name="username"
                        label="User Name"
                        autoFocus
                        onChange={formik.handleChange}
                        value={formik.values.username}
                      />
                      <FormInput
                        type="text"
                        name="tranid"
                        label="Enter Transaction No"
                        autoFocus
                        onChange={formik.handleChange}
                        value={formik.values.tranid}
                      />
                      <FormInput
                        type="money"
                        name="amount"
                        label="Enter Amount"
                        onChange={formik.handleChange}
                        value={formik.values.amount}
                      />
                    </div>
                    {isButtonDisabled === false ? (
                      <Button type="submit" variant="outlined" size="large">
                        Submit
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        variant="outlined"
                        startIcon={<CircularProgress />}
                        size="large"
                      >
                        Submiting..
                      </Button>
                    )}
                  </Form>
                </FormikProvider>
                
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} className="rightside">
            <Card>
              <CardContent>
                <h3>Deposit History</h3>
                <Box sx={{ height: 400, width: "100%", marginTop: 3 }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 5,
                        },
                      },
                    }}
                    pageSizeOptions={[5]}
                    disableRowSelectionOnClick
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Dialog open={open} maxWidth="sm" fullWidth={true}>
            <DialogTitle>Bank Details</DialogTitle>
            <DialogContent className="dialog">
              <Alert>Deposit to this account only:</Alert>
              <div className="cont">
                <div className="head">Bank Name</div>
                <div className="body">
                  Yes Bank
                  <IconButton aria-label="delete" onClick={()=>copyText('Yes Bank')}>
                    <CopyAllOutlinedIcon />
                  </IconButton>
                </div>
              </div>
              <div className="cont">
                <div className="head">Account Holder</div>
                <div className="body">
                  SOARXBITS PVT. LTD.
                  <IconButton aria-label="delete" onClick={()=>copyText('SOARXBITS PVT. LTD.')}>
                    <CopyAllOutlinedIcon />
                  </IconButton>
                </div>
              </div>
              <div className="cont">
                <div className="head">Branch Name</div>
                <div className="body">
                  Siliguri
                  <IconButton aria-label="delete" onClick={()=>copyText('SILIGURI')}>
                    <CopyAllOutlinedIcon />
                  </IconButton>
                </div>
              </div>
              <div className="cont">
                <div className="head">Account Number</div>
                <div className="body">
                  121163700000075
                  <IconButton aria-label="delete" onClick={()=>copyText('121163700000075')}>
                    <CopyAllOutlinedIcon />
                  </IconButton>
                </div>
              </div>
              <div className="cont">
                <div className="head">IFSC Code</div>
                <div className="body">
                  YESB0001211
                  <IconButton aria-label="delete" onClick={()=>copyText('YESB0001211')}>
                    <CopyAllOutlinedIcon />
                  </IconButton>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
          </Dialog>
        </Grid>
        
      </div>
    </>
  );
};
