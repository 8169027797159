import { Form, Formik, FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import { FormInput } from "../forminput/FormInput";
import { Button, Divider } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { toast, ToastContainer } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import * as Yup from "yup";
import axios from "axios";
import Cookies from "js-cookie";
export const StepTwo = (props) => {
  const [checktry, setCheckTry] = useState(3);
  const [btnloading, setBtnloading] = useState(false)
  const bankcheck = Cookies.get('bankcheck')
  const url = process.env.REACT_APP_HOST_ADDR;
  const validationSchema = Yup.object({
    accno: Yup.number("Invalid Account number").required(
      "Field can not be empty."
    ),
    ifsc: Yup.mixed("").required("Field can not be empty."),
    bankname: Yup.mixed("").required("Field can not be empty."),
    branch: Yup.mixed("").required("Field can not be empty."),
    bankstate: Yup.mixed("").required("Field can not be empty."),
    city: Yup.mixed("").required("Field can not be empty."),
  });

  const formik = useFormik({
    initialValues: props.data,
    validationSchema,
    onSubmit: async (values) => {
      props.next(values);
    },
  });
  const bankCheck = () => {
    const {accno, ifsc} = formik.values;
    formik.setFieldValue("bankfetch",1)
    if((accno=='')||(ifsc=='')){
      notify("INVALID BANK DETAILS DETECTED.");
      formik.setFieldValue("bankfetch",0)
      return false
    }
    if((checktry > 0)&&(bankcheck != '0')){
      setCheckTry(checktry - 1)
      axios
      .post(url + "/user/checkbank", {
        accno,
        ifsc
      })
      .then(function (respdata) {
        formik.setFieldValue("bankname", respdata.data.respdata.bank_name);
        formik.setFieldValue("branch", respdata.data.respdata.branch);
        formik.setFieldValue("bankstate", respdata.data.respdata.state);
        formik.setFieldValue("city", respdata.data.respdata.city);
        formik.setFieldValue("bankfetch",2)
      })
      .catch(function (error) {
        formik.setFieldValue("bankfetch",0)
        console.log(error);
      });
    }else{
      notify("You have crossed Max Check Limit! Contact support.");
      Cookies.set('bankcheck','0')
    }
    
  };
  const notify = (msg) => toast(msg);
  return (
    <>
    <ToastContainer />
      <div className="stepwreper">
        <FormikProvider value={formik}>
          <Form autoComplete="off" className="stepone">
            <div className="title">
              <h2>Enter Bank Details</h2>
            </div>
            <div className="content">
              <div className="inputdata">
              <div className="counter">
                  <p>Try Left:</p>
                  <p>{checktry}</p>
                </div>
              <FormInput
                  type="text"
                  name="accno"
                  label="Account Number *"
                  onChange={formik.handleChange}
                  value={formik.values.accno}
                />
                <FormInput
                  type="text"
                  name="ifsc"
                  label="IFSC *"
                  onChange={formik.handleChange}
                  value={formik.values.ifsc}
                />
                {formik.values.bankfetch == 0 ? (
                  <Button variant="outlined" onClick={bankCheck}>
                    Check Bank
                  </Button>
                ) : (
                  <>
                    {formik.values.bankfetch == 1 ? (
                      <Button
                        variant="outlined"
                        startIcon={<CircularProgress />}
                      >
                        Fetching Data
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        startIcon={<CheckCircleOutlineIcon />}
                      >
                        Data Fetched
                      </Button>
                    )}
                  </>
                )}
                {/* {btnloading==false?
                <Button variant="outlined" onClick={bankCheck}>
                Check Bank
              </Button>
                :
                <Button variant="outlined" startIcon={<CircularProgress />} >
                  Check Bank
                </Button>
                } */}
                <FormInput
                  type="text"
                  name="bankname"
                  label="Bank Name *"
                  disabled
                  onChange={formik.handleChange}
                  value={formik.values.bankname}
                />
                <FormInput
                  type="text"
                  name="branch"
                  label="Branch Name *"
                  disabled
                  onChange={formik.handleChange}
                  value={formik.values.branch}
                />
                <FormInput
                  type="text"
                  name="bankstate"
                  label="State *"
                  disabled
                  onChange={formik.handleChange}
                  value={formik.values.bankstate}
                />
                <FormInput
                  type="text"
                  name="city"
                  label="city *"
                  disabled
                  onChange={formik.handleChange}
                  value={formik.values.city}
                />
                
              </div>
              <div className="buton">
                <Button
                  variant="contained"
                  type="button"
                  startIcon={<NavigateBeforeIcon />}
                  onClick={() => props.prev(formik.values)}
                >
                  Previous
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  endIcon={<NavigateNextIcon />}
                >
                  NEXT
                </Button>
              </div>
            </div>
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};
