// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.theme-light .tradewreper .form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #000;
}
.theme-light .tradewreper .form-group label {
  color: #000;
}
.theme-light .tradewreper .form-group fieldset {
  border-color: #b3acac;
}
.theme-light .tradewreper .form-group input {
  color: #000 !important;
}
.theme-light .tradewreper .form-group .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  color: #000;
  -webkit-text-fill-color: #000;
}
.theme-light .tradewreper .form-group .balance {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
  background-color: antiquewhite;
  color: #000;
  padding: 10px;
  border-radius: 8px;
}
.theme-light .tradewreper .form-group .calc {
  display: flex;
  justify-content: space-between;
}
.theme-dark .tradewreper .form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: whitesmoke;
}
.theme-dark .tradewreper .form-group label {
  color: whitesmoke;
}
.theme-dark .tradewreper .form-group fieldset {
  border-color: lightgray;
}
.theme-dark .tradewreper .form-group input {
  color: whitesmoke !important;
}
.theme-dark .tradewreper .form-group .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  color: whitesmoke;
  -webkit-text-fill-color: whitesmoke;
}
.theme-dark .tradewreper .form-group .balance {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
  background-color: antiquewhite;
  color: #000;
  padding: 10px;
  border-radius: 8px;
}
.theme-dark .tradewreper .form-group .calc {
  display: flex;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/components/usertrade/usertrade.scss"],"names":[],"mappings":"AAGI;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,WAAA;AAFN;AAGM;EACE,WAAA;AADR;AAGM;EACE,qBAAA;AADR;AAGM;EACE,sBAAA;AADR;AAGM;EACE,WAAA;EACA,6BAAA;AADR;AAGM;EACE,aAAA;EACA,8BAAA;EACA,kBAAA;EACA,8BAAA;EACA,WAAA;EACA,aAAA;EACA,kBAAA;AADR;AAGM;EACE,aAAA;EACA,8BAAA;AADR;AA5BI;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,iBAAA;AA8BN;AA7BM;EACE,iBAAA;AA+BR;AA7BM;EACE,uBAAA;AA+BR;AA7BM;EACE,4BAAA;AA+BR;AA7BM;EACE,iBAAA;EACA,mCAAA;AA+BR;AA7BM;EACE,aAAA;EACA,8BAAA;EACA,kBAAA;EACA,8BAAA;EACA,WAAA;EACA,aAAA;EACA,kBAAA;AA+BR;AA7BM;EACE,aAAA;EACA,8BAAA;AA+BR","sourcesContent":["@import \"../../style.scss\";\n.tradewreper {\n  @include themify($themes) {\n    .form-group {\n      display: flex;\n      flex-direction: column;\n      gap: 10px;\n      color: themed(\"textColor\");\n      label {\n        color: themed(textColor);\n      }\n      fieldset {\n        border-color: themed(textColorSoft);\n      }\n      input {\n        color: themed(\"textColor\") !important;\n      }\n      .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled{\n        color: themed(textColor);\n        -webkit-text-fill-color:themed(textColor);\n      }\n      .balance{\n        display: flex;\n        justify-content: space-between;\n        margin-bottom: 3px;\n        background-color: antiquewhite;\n        color: #000;\n        padding: 10px;\n        border-radius: 8px;\n      }\n      .calc{\n        display: flex;\n        justify-content: space-between;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
