import React, { useEffect, useState } from "react";
import "./profile.scss";
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from "@mui/material";
import Cookies from "js-cookie";
import * as Yup from "yup";
import axios from "axios";
import { Form, FormikProvider, useFormik } from "formik";
import { FormInput } from "../../../components/forminput/FormInput";
import CircularProgress from "@mui/material/CircularProgress";
export const Profile = () => {
  const [open, setOpen] = useState(false);
  const [urlCode, setUrlCode] = useState("");
  const [qrimage, setQrImage] = useState("");
  const [data, setData] = useState({
    user_name: "NA",
    pan: "NA",
    aadhar: "NA",
    accno: "NA",
    branch: "NA",
    ifsc: "NA",
  });
  const [tofa, setTofa] = useState({});
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apiurl = process.env.REACT_APP_API_ADDR;
  var token = Cookies.get("token");
  var platform_id = Cookies.get("sorxplatformid");
  useEffect(() => {
    if (token) {
      const fetchData = async () => {
        var profileData = await axios.post(url + "/user/getbankdetail", {
          platform_id,
        });
        var userdata = await axios.get(apiurl + "/user/user/data", {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        if (profileData.data.data != null) {
          setData(profileData.data.data);
        }
        setTofa(userdata.data);
      };
      fetchData();
    }
  }, []);
  const validationSchema = Yup.object({
    mycode: Yup.mixed("Invalid Auth Code, Please Check!!!").required(
      "Field can not be empty."
    ),
  });
  const formik = useFormik({
    initialValues: {
      mycode: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      console.log(values.mycode);
      const resp = await axios.post(
        apiurl + "/auth/complete-totp-binding",
        {
          code: String(values.mycode),
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      setOpen(false);
    },
  });
  const gentotp = () => {
    axios
      .get(apiurl + "/auth/initiate-totp-binding", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        let new_image = "data:image/png;base64," + response.data.code;
        console.log(response.data.url);
        const secretStartIndex = response.data.url.indexOf("secret=") + 7;
        const secretEndIndex = response.data.url.indexOf("&", secretStartIndex);
        const secret = response.data.url.substring(
          secretStartIndex,
          secretEndIndex !== -1 ? secretEndIndex : data.url.length
        );
        setUrlCode(secret);
        setQrImage(new_image);
        setOpen(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleClose = () => {
    setOpen(false);
  };
  const copyText = (x) => {
    navigator.clipboard.writeText(x);
  };
  return (
    <>
      <div className="profilewreper">
        <Grid container spacing={2} className="profile">
          <Grid item xs={12} md={7} className="leftside">
            <Card sx={{ backgroundColor: "transparent" }}>
              <CardHeader title="Personal Info" />
              <CardContent className="cardbody">
                <div className="cont">
                  <div className="body">
                    <p>NAME:</p>
                    <p>{data.user_name}</p>
                  </div>
                  <div className="body">
                    <p>PAN:</p>
                    <p>{data.pan}</p>
                  </div>
                  <div className="body">
                    <p>AADHAR:</p>
                    <p>{data.aadhar}</p>
                  </div>
                  <div className="body">
                    <p>EMAIL:</p>
                    <p>{tofa.email}</p>
                  </div>
                  <div className="body">
                    <p>PHONE:</p>
                    <p>{tofa.phone_number}</p>
                  </div>
                  <div className="body">
                    <p>REF. CODE:</p>
                    <p>{platform_id}</p>
                  </div>
                  <Alert>To Alter this data contact admin</Alert>
                </div>
              </CardContent>
            </Card>
            <Card
              sx={{ backgroundColor: "transparent" }}
              style={{ marginTop: "30px" }}
            >
              <CardHeader title="Banking Info" />
              <CardContent className="cardbody">
                <div className="cont">
                  <div className="body">
                    <p>Account No:</p>
                    <p>{data.accno}</p>
                  </div>
                  <div className="body">
                    <p>BRANCH:</p>
                    <p>{data.branch}</p>
                  </div>
                  <div className="body">
                    <p>IFSC:</p>
                    <p>{data.ifsc}</p>
                  </div>
                  <Alert>To Alter this data contact admin</Alert>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={5}>
            <div className="tofa">
              <Card sx={{ backgroundColor: "transparent" }}>
                <CardHeader title="2FA!" />
                <CardContent className="cardbody">
                  {tofa.is_totp_enabled ? (
                    <Alert>2FA is Active. Enjoy Quick Login.</Alert>
                  ) : (
                    <>
                      <Alert severity="error">
                        2FA Not Active!! Activate Now.
                      </Alert>
                      <div className="faactive">
                        <div className="butt">
                          <Button
                            variant="contained"
                            color="warning"
                            onClick={() => gentotp()}
                          >
                            Activate 2FA
                          </Button>
                        </div>
                      </div>
                    </>
                  )}
                </CardContent>
              </Card>
              {/* <Card>
                <CardContent>
                  <Button
                    variant="contained"
                    color="warning"
                    href="/user/buysell"
                  >
                    BUY SELL REPORT
                  </Button>
                </CardContent>
              </Card> */}
            </div>
          </Grid>
        </Grid>
      </div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="mydlg"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ backgroundColor: "#990000", color: "#FFF" }}
        >
          2FA Verification
        </DialogTitle>
        <Divider />
        <DialogContent
          className="dlgcont"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            className="qrpart"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img src={qrimage} width="150px" height="150px" alt="NO IMAGE" />
            <p
              style={{
                width: "250px !important",
                maxWidth: "250px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {urlCode}
            </p>
            <Button onClick={() => copyText(urlCode)}>Copy Secret</Button>
          </div>
          <div className="formelement" style={{ width: "100%" }}>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="form-group">
                  <FormInput
                    type="number"
                    name="mycode"
                    label="Enter Code"
                    autoFocus
                    onChange={formik.handleChange}
                    value={formik.values.mycode}
                  />
                  <div
                    className="buttons"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: 10,
                    }}
                  >
                    <Button onClick={() => handleClose()}>Cancel</Button>
                    {isButtonDisabled === false ? (
                      <Button type="submit" variant="outlined" size="large">
                        Submit
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        variant="outlined"
                        startIcon={<CircularProgress />}
                        size="large"
                      >
                        Submiting..
                      </Button>
                    )}
                  </div>
                </div>
              </Form>
            </FormikProvider>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
