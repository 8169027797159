import React from "react";
import { TextField } from "formik-mui";
import { Field } from "formik";
export const FormInput = (props) => {
  const { onChange, error = null } = props;
  return (
    <Field
      component={TextField}
      name={props.name}
      type={props.type}
      label={props.label}
      value={props.value}
      disabled = {props.disabled}
      variant="outlined"
      fullWidth
      size="small"
      onChange = {props.onChange}
      InputProps={props.InputProps}
      {...(error && { error: true, helperText: error })}
    />
  );
};
