import React, { useEffect, useState } from "react";
import "./cryptowithdraw.scss";
import { CryptWithdraw } from "../../../components/cryptwithdraw/CryptWithdraw";
import { AddressUpdate } from "../../../components/addressupdate/AddressUpdate";
import Cookies from "js-cookie";
import axios from "axios";
import {Card, CardContent, Grid } from "@mui/material";
export const CryptoWithdraw = () => {
  const [vissible, setVissible] = useState(false)
  const url = process.env.REACT_APP_HOST_ADDR;
  var platform_id = Cookies.get("sorxplatformid");
  const [data, setData] = useState({
    receipt_link: "",
    mobile: "",
  });
  useEffect(() => {
    axios
      .post(url + "/user/getbankdetail", {
        platform_id,
      })
      .then(function (response) {
        if (response.data.data != null) {
          setData(response.data.data);
          setVissible(true)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [data.mobile]);
  return (
    <>
      <div className="cryptowithdraw">
        <Grid container spacing={2}>
          <Grid item xs={12} md={7} className="leftside">
            <Card sx={{ backgroundColor: "transparent" }} className="mycard">
              <CardContent className="cardcont">
                {(data.receipt_link ==="" || data.receipt_link == null) ? (
                <>
                  {vissible && <AddressUpdate mobile={data.mobile} />}
                  </>
                    
                ) : (
                  <>
                  {vissible && <CryptWithdraw />}
                  </>
                    
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
