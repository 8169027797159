import React from "react";
import "./orders.scss";
import { Grid } from "@mui/material";
import { UserActOrder } from "../../../components/userorders/UserActOrder";
import { UserAllOrder } from "../../../components/userorders/UserAllOrder";
export const Orders = () => {
  return (
    <>
      <div className="userorders">
        <Grid container spacing={2} className="orders">
          <Grid item xs={12} md={6} className="leftside">
            <UserActOrder pair="SOARXINR" />
          </Grid>
          <Grid item xs={12} md={6} className="rightside">
          <UserAllOrder pair="SOARXINR" />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
