import React, { useEffect, useState } from "react";
import "./intrade.scss";
import {
  Divider,
} from "@mui/material";
import { BuyTable } from "../table/BuyTable";
import { SellTable } from "../table/SellTable";
const URL_WEB_SOCKET = "wss://api.soarxbits.com/ws/subscribe";
const request = {
  operation: "subscribe",
  channel: ["marketdata", "orders"],
  interval: 2,
  market: "SOARXINR",
  topics: ["orderbook"],
};
export const Intrade = (props) => {
  const [ws, setWs] = useState(null);
  const [trades, setTrades] = useState([]);
  const [bids, setBids] = useState([]);
  const [asks, setAsks] = useState([]);
  useEffect(() => {
    const wsClient = new WebSocket(URL_WEB_SOCKET);
    wsClient.onopen = () => {
      setWs(wsClient);
      wsClient.send(JSON.stringify(request));
    };
    wsClient.onclose = () => console.log("ws closed");
    return () => {
      wsClient.close();
    };
  }, []);
  useEffect(() => {
    if (ws) {
      ws.onmessage = (evt) => {
        const trade = JSON.parse(evt.data);
        //const preTrades = [...trades];
        addTradeToList(trade);
      };
    }
  }, [ws, trades]);
  async function addTradeToList(data) {
    if (data.reset === true) {
      let alldata = data.orders
      var buys = alldata.filter(function (entry) {
        return (
          entry.side === "Ask"
        );
      });
      setBids(buys)
      var sells = alldata.filter(function (entry) {
        return (
          entry.side === "Bid"
        );
      });
      setAsks(sells)
    }
  }
  return (
    <>
      <div className="tradeeng">
      <SellTable data={bids} />
      <Divider />
        <BuyTable data={asks} />
        
      </div>
    </>
  );
};
