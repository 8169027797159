import React, { useEffect, useState } from "react";
import "./hotmarket.scss";
import axios from "axios";
import imgbtc from "../../images/btc.png";
import imgeth from "../../images/eth.png";
import logo from "../../images/soarxcoin.png";
import imgusdt from "../../images/usdt.png";
export const Hotmarket = () => {
  const [btc, setBtc] = useState({});
  const [eth, setEth] = useState({});
  const [usdtx, setUsdtx] = useState({});
  const url = process.env.REACT_APP_HOST_ADDR;
  const apiurl = process.env.REACT_APP_API_ADDR;
  const [primecoin, setPrimecoin] = useState({});
  const [soarx, setSoarx] = useState({});
  const [usdt, setUsdt] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      const prime = await axios.get(url + "/primemarket");
      var ticker = await axios.get(apiurl + "/ticker/tickers/SOARXINR");
      var usdtinr = await axios.get("https://api.coindcx.com/exchange/ticker");
      usdtinr = usdtinr.data;
      var usdtPrice = usdtinr.filter(function (entity) {
        return entity.market == "USDTINR";
      });
      setUsdt(usdtPrice[0].last_price);

      var btuusdt = await axios.get("https://api.coindcx.com/exchange/ticker");
      btuusdt = btuusdt.data;
      var btcdata = btuusdt.filter(function (entity) {
        return entity.market == "BTCUSDT";
      });
      setBtc(btcdata[0]);
      var ethdata = btuusdt.filter(function (entity) {
        return entity.market == "ETHUSDT";
      });
      setEth(ethdata[0]);
      var usdtdata = btuusdt.filter(function (entity) {
        return entity.market == "USDTINR";
      });
      setUsdtx(usdtdata[0]);

      setPrimecoin(prime.data.data);
      setSoarx(ticker.data.ticker);
    };
    fetchData();
  }, []);
  return (
    <>
      <div className="hotmarket">
        <div className="grpone">
          <div className="scrollcont">
            <img src={imgbtc} alt="" />
            <div className="coin">
              <span className="coinname">BTC/USDT</span>
              {btc.change_24_hour * 1 < 0 ? (
                <span className="change low">{btc.change_24_hour}</span>
              ) : (
                <span className="change high">{btc.change_24_hour}</span>
              )}
            </div>
            <div className="high">{(btc.high * 1).toFixed(3)}</div>
            <div className="price">{(btc.volume * 1).toFixed(3)}</div>
          </div>
          <div className="scrollcont">
            <img src={imgeth} alt="" />
            <div className="coin">
              <span className="coinname">ETH/USDT</span>
              {eth.change_24_hour * 1 < 0 ? (
                <span className="change low">{eth.change_24_hour}</span>
              ) : (
                <span className="change high">{eth.change_24_hour}</span>
              )}
            </div>
            <div className="high">{(eth.high * 1).toFixed(3)}</div>
            <div className="price">{(eth.volume * 1).toFixed(3)}</div>
          </div>
        </div>
        <div className="grptwo">
          <div className="scrollcont active">
            <img src={logo} alt="" />
            <div className="coin">
              <span className="coinname">SOARX/USDT</span>
              <span className="change high">3.001</span>
            </div>
            <div className="high">0.0048</div>
            <div className="price">3819281.00</div>
          </div>
          <div className="scrollcont">
            <img src={imgusdt} alt="" />
            <div className="coin">
              <span className="coinname">USDT/INR</span>
              {usdtx.change_24_hour * 1 < 0 ? (
                <span className="change low">{usdtx.change_24_hour}</span>
              ) : (
                <span className="change high">{usdtx.change_24_hour}</span>
              )}
            </div>
            <div className="high">{(usdtx.high * 1).toFixed(3)}</div>
            <div className="price">{(usdtx.volume * 1).toFixed(3)}</div>
          </div>
        </div>
      </div>
    </>
  );
};
