import React, { useEffect, useState } from "react";
import "./steps.scss";
import { Field, Form, FormikProvider, useFormik } from "formik";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { FormInput } from "../forminput/FormInput";
import {
  Alert,
  AlertTitle,
  Button,
  Divider,
  FormControlLabel,
} from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import axios from "axios";
import Cookies from "js-cookie";
import { toast, ToastContainer } from "react-toastify";
export const StepAadhar = (props) => {
  const [checktry, setCheckTry] = useState(3);
  const [btnloading, setBtnloading] = useState(false)
  const aadharcheck = Cookies.get('aadharcheck')
  const url = process.env.REACT_APP_HOST_ADDR;

  const validationSchema = Yup.object({
    aadhar: Yup.string("Invalid aadhar found")
      .min(12, "Invalid Aadhar")
      .max(12, "Invalid Aadhar")
      .required("Field can not be empty."),
      state: Yup.string("Invalid State found")
      .required("Please Check Aadhaar to fetch State."),
  });
  const formik = useFormik({
    initialValues: props.data,
    validationSchema,
    onSubmit: async (values) => {
      props.next(values);
    },
  });
  const aadharCheck = () => {
    formik.setFieldValue("aadharfetch",1)
    const aadhar = formik.values.aadhar;
    if((aadhar=='')||(aadhar.length < 12)){
      notify("INVALID AADHAR NUMBER DETECTED.");
      formik.setFieldValue("aadharfetch",0)
      return false
      
    }
    if((checktry > 0)&&(aadharcheck != '0')){
      setCheckTry(checktry - 1)
      axios
      .post(url + "/user/checkaadhar", {
        aadhar,
      })
      .then(function (response) {
        formik.setFieldValue("state", response.data.state);
        formik.setFieldValue("aadharfetch",2)
      })
      .catch(function (error) {
        console.log(error);
        formik.setFieldValue("aadharfetch",0)
      });
    }else{
      notify("You have crossed Max Check Limit! Contact support.");
      Cookies.set('aadharcheck','0')
    }
    
  };
  useEffect(()=>{
    if(aadharcheck==0){
      setCheckTry(0)
    }
  },[aadharcheck])
  const notify = (msg) => toast(msg);
  return (
    <>
    <ToastContainer />
      <div className="stepwreper">
        <FormikProvider value={formik}>
          <Form
            autoComplete="off"
            className="stepone"
            onSubmit={formik.handleSubmit}
          >
            <div className="title">
              <h2>Aadhaar Update: </h2>
              <p>All Fields are Required *</p>
            </div>

            <Divider />
            <div className="content">
              <div className="inputdata">
                <Alert severity="info">
                  <AlertTitle>MAX 3 INVALID CHECK ALLOWED.</AlertTitle>
                  Enter correct AADHAAR No & Hit Check Button.
                </Alert>
                <div className="counter">
                  <p>Try Left:</p>
                  <p>{checktry}</p>
                </div>
                <FormInput
                  type="number"
                  name="aadhar"
                  label="Aadhaar Number *"
                  onChange={formik.handleChange}
                  value={formik.values.aadhar}
                />
                {formik.values.aadharfetch == 0 ? (
                  <Button variant="outlined" onClick={aadharCheck}>
                    Check Aadhaar
                  </Button>
                ) : (
                  <>
                    {formik.values.aadharfetch == 1 ? (
                      <Button
                        variant="outlined"
                        startIcon={<CircularProgress />}
                      >
                        Fetching Data
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        startIcon={<CheckCircleOutlineIcon />}
                      >
                        Data Fetched
                      </Button>
                    )}
                  </>
                )}
                {/* {btnloading==false?
                <Button variant="outlined" onClick={aadharCheck}>
                Check Aadhar
              </Button>
                :
                <Button variant="outlined" startIcon={<CircularProgress />} >
                  Check Aadhar
                </Button>
                } */}
                <FormInput
                  type="text"
                  name="state"
                  disabled
                  label="State *"
                  onChange={formik.handleChange}
                  value={formik.values.state}
                />
              </div>

              <div className="buton">
                <Button
                  variant="contained"
                  type="button"
                  startIcon={<NavigateBeforeIcon />}
                  onClick={() => props.prev(formik.values)}
                >
                  Previous
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  endIcon={<NavigateNextIcon />}
                >
                  NEXT
                </Button>
              </div>
            </div>
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};
