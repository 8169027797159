import { Form, FormikProvider, useFormik } from "formik";
import React from "react";
import axios from "axios";
import * as Yup from "yup";
import { Button, Divider } from "@mui/material";
import { FormInput } from "../../../components/forminput/FormInput";
export const PanUpload = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const validationSchema = Yup.object({});
  const formik = useFormik({
    initialValues: {
      avtar: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      //disableButton(true);
      console.log(values);
      //alert(JSON.stringify(values, null, 2));
      var formData = new FormData();
      formData.append('avtar', values.avtar)
      console.log(formData)
      // for (let value in values) {
      //   formData.append(value, values[value]);
      // }
      const file = values.avtar
      axios
        .post(url + "/userkyc/panupload", {
          file,
        },{
          headers:{
            "Content-Type":'multipart/form-data',
          }
        })
        .then(function (response) {
          console.log(response.data);
          //   if (response.status === 200) {
          //     Navigate(
          //       "/displaymsg/Success/Profile Updated Successfully. Please Proceed to KYC UPDATE/kycdata/2"
          //     );
          //   }
        })
        .catch(function (error) {
          console.log(error.response.data.status);
        });
    },
  });
  return (
    <>
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          className="stepone"
          onSubmit={formik.handleSubmit}
          encType="multipart/form-data"
        >
          <div className="title">
            <h2>Enter Personal Info: </h2>
            <p>All Fields are Required *</p>
          </div>
          <br />
          <br />
          <br />
          <br />
          <Divider />
          <div className="content">
            <div className="inputdata">
              <input
                type="file"
                name="avtar"
                onChange={(e) => {
                  formik.setFieldValue("avtar",  e.target.files[0]);
                  // const reader = new FileReader();
                  // try {
                  //   //var data = reader.readAsDataURL(file);
                  //   reader.onload = () => {
                  //     if (reader.readyState === 2) {
                  //       //formik.setFieldValue("panimage", e.target.files[0]);
                  //       formik.setFieldValue("avtar",  e.target.files[0]);
                  //       //setPreview(reader.result);
                  //     }
                  //   };
                  // } catch (error) {
                  //   console.log(error);
                  // }
                  // reader.readAsDataURL(e.target.files[0]);
                  // formik.setFieldValue("panimage", e.target.files[0]);
                  // formik.setFieldValue("pandata", e.target.files[0]);
                }}
              />
            </div>

            <div className="buton">
              <Button variant="contained" type="submit">
                NEXT
              </Button>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </>
  );
};
