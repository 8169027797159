import React from "react";
import "./officedashboard.scss";
import { Card } from "primereact/card";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
export const OfficeDashboard = () => {
  return (
    <div className="officedashboard">
      <Button
        href="/office/tdsreport"
        variant="contained"
        // startIcon={<PermIdentityOutlinedIcon />}
        // sx={{ width: "100%" }}
      >
        TDS DETAILS
      </Button>
      <Button
        href="/office/gstreport"
        variant="contained"
        // startIcon={<PermIdentityOutlinedIcon />}
        sx={{ marginLeft: 3 }}
      >
        GST REPORT
      </Button>
      {/* <div className="grid">
        <div className="col-6 flex">
          <Link to="/office/tdsreport">
            <Button label="TDS REPORT" className="mr-3" />
          </Link>
          <Link to="/office/gstreport">
          <Button label="GST REPORT" />
          </Link>
        </div>
      </div> */}
    </div>
  );
};
