import React, { useEffect, useState } from "react";
import "./buysell.scss"
import {
    Alert,
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
  } from "@mui/material";
  import Cookies from "js-cookie";
import * as Yup from "yup";
import axios from "axios";
export const BuySell = () => {
    const [data, setData] = useState({})
    const url = process.env.REACT_APP_HOST_ADDR;
  const apiurl = process.env.REACT_APP_API_ADDR;
  var token = Cookies.get("token");
  var platform_id = Cookies.get("sorxplatformid");
  useEffect(() => {
    if (token) {
      const fetchData = async () => {
        var profileData = await axios.post(url + "/user/buysellinr", {
          platform_id,
        });
        // var userdata = await axios.get(apiurl + "/user/user/data", {
        //   headers: {
        //     Authorization: "Bearer " + token,
        //   },
        // });
        // if (profileData.data.data != null) {
        //   setData(profileData.data.data);
        // }
        // setTofa(userdata.data);
      };
      fetchData();
    }
  }, []);
  return (
    <div className='buysell'>BuySell</div>
  )
}
