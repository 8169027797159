import { Alert, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./cryptowithdrawdlg.scss";
import Cookies from "js-cookie";
import axios from "axios";
import * as Yup from "yup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, FormikProvider, useFormik } from "formik";
import { FormInput } from "../forminput/FormInput";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
export const CryptWithdraw = () => {
  const Navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [data, setData] = useState({});
  const [receiptlink, setReceiptLink] = useState("");
  const apiurl = process.env.REACT_APP_API_ADDR;
  const url = process.env.REACT_APP_HOST_ADDR;
  var token = Cookies.get("token");
  var platform_id = Cookies.get("sorxplatformid");
  useEffect(() => {
    axios
      .get(url + "/user/balances/spot", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        setData(response.data.coinlist);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          //Navigate("/");
        }
      });
  }, []);
  useEffect(() => {
    console.log("Platform Id: ", platform_id)
    axios
      .post(url + "/user/getbankdetail", {
        platform_id
      })
      .then(function (response) {
        if(response.data.data != null){
          setReceiptLink(response.data.data.receipt_link)
        }
      })
      .catch(function (error) {
        console.log(error)
      });
  }, []);
  const validationSchema = Yup.object({
    withamount: Yup.number().required().min(200, "Minumum 200 is allowed"),
  });
  const formik = useFormik({
    initialValues: {
      platform_id: platform_id,
      activecurrency: "",
      balance: "",
      withamount: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setButtonDisabled(true)
      const { balance, withamount, activecurrency } = values;
      if(withamount*1 > balance *1){
        setButtonDisabled(false)
        return false
      }else{
        axios
        .post(url + "/user/cryptwithdraw", {
          platform_id,
          withamount,
          receiptlink,
          activecurrency
        },{
          headers:{
            Authorization: "Bearer " + token
          }
        })
        .then(function (response) {
          Navigate(
            "/displaymsg/Success/Your request submitted successfully. Please Proceed.../cryptowithdraw/2"
          );
        })
        .catch(function (error) {
          notify("Something went wrong!!!");
          setButtonDisabled(false)
        });
      }
      
    },
  });
  const handleClickOpen = (x, y) => {
    formik.setFieldValue("activecurrency", x);
    formik.setFieldValue("balance", y);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    formik.setFieldValue("activecurrency", '');
    formik.setFieldValue("balance", '');
    formik.setFieldValue("withamount", '');
    
  };
  const calculate = (x) => {
    formik.setFieldValue("withamount", formik.values.balance * x /100);
  };
  
  const notify = (msg) => toast(msg);
  if (!data) return false;
  const rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }
  return (
    <>
    <ToastContainer />
      <div className="title">
        <p>Withdraw</p>
      </div>
      <Alert>
        Seems you have updated received link.
      </Alert>
      <div className="tble">
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Coin/Token</TableCell>
                <TableCell align="center">Total</TableCell>
                <TableCell align="center">In Trade</TableCell>
                <TableCell align="center">Balance</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.currency}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.currency.toUpperCase()}
                  </TableCell>
                  <TableCell align="center">
                    {row.balance + row.frozen_balance}
                  </TableCell>
                  <TableCell align="center">{row.frozen_balance}</TableCell>
                  <TableCell align="center">{row.balance}</TableCell>
                  <TableCell align="center">
                    {row.balance > 0 ? (
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() =>
                          handleClickOpen(row.currency, row.balance)
                        }
                      >
                        Withdraw
                      </Button>
                    ) : (
                      <Button variant="outlined" disabled={true} size="small">
                        Withdraw
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="sm"
        className="mydlg"
      >
        <DialogTitle>
          Withdraw : {formik.values.activecurrency.toUpperCase()}
        </DialogTitle>
        <DialogContent>
          <FormikProvider value={formik}>
            <Form
              onSubmit={formik.handleSubmit}
              autoComplete="off"
              className="crptwithform"
            >
              <Alert sx={{marginBottom:2}}>{receiptlink}</Alert>
              <FormInput
                type="text"
                name="withamount"
                label="Amount"
                onChange={formik.handleChange}
                value={formik.values.withamount}
              />
              <div
                className="available"
                style={{
                  backgroundColor:'#81d4fa',
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 8,
                  marginTop: 15,
                  marginBottom: 15,
                  borderRadius: 5,
                  color:'#000'
                }}
              >
                <p>Available Balance</p>
                <p>{formik.values.balance}</p>
              </div>
              <div
                className="calc"
                style={{ display: "flex", justifyContent: "space-between", backgroundColor:'#c8e6c9', padding: '8px', borderRadius:'8px' }}
              >
                <Button variant="outlined" onClick={() => calculate(25)}>
                  25%
                </Button>
                <Button variant="outlined" onClick={() => calculate(50)}>
                  50%
                </Button>
                <Button variant="outlined" onClick={() => calculate(75)}>
                  75%
                </Button>
                <Button variant="outlined" onClick={() => calculate(100)}>
                  100%
                </Button>
              </div>
              <div className="buttons" style={{display:"flex", alignItems:"center", justifyContent:'space-between', marginTop:"28px"}}>
              {isButtonDisabled === false ? (
                <Button type="submit" variant="outlined" size="large">
                  Submit
                </Button>
              ) : (
                <Button
                  type="button"
                  variant="outlined"
                  startIcon={<CircularProgress />}
                  size="large"
                >
                  Submiting..
                </Button>
              )}
              <Button variant="contained" color="warning" size="large" onClick={()=>handleClose()} >Close</Button>
              </div>
              
            </Form>
          </FormikProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};
