import React, { useEffect, useState } from "react";
import "./dashboard.scss";
import axios from "axios";
import Cookies from "js-cookie";
import img1 from "../../../images/slide-1.webp";
import img2 from "../../../images/slide-2.webp";
import img3 from "../../../images/slide-3.webp";
import img4 from "../../../images/slide-4.webp";
import { Alert, Button, Grid } from "@mui/material";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import BadgeIcon from "@mui/icons-material/Badge";
import { Link } from "react-router-dom";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import { Hotmarket } from "../../../components/hotmarket/Hotmarket";
import Slider from "react-slick";
export const Dashboard = () => {
  var settings = {
    autoplay: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerPadding: "50px",
  };
  const [vissible, setVissible] = useState(false);
  Cookies.set("soarxactivepair", "SOARXINR");
  const apiurl = process.env.REACT_APP_API_ADDR;
  const [data, setData] = useState({
    mobverify: false,
    reason: "",
    tofa: "",
    username: "",
    kycLevel: "",
    inrdeposit: "",
    inrwithdraw: "",
    cryptodeposit: "",
    cryptowithdraw: "",
  });
  var token = Cookies.get("token");
  useEffect(() => {
    if (token) {
      const fetchData = async () => {
        const response = await axios.get(apiurl + "/user/user/data", {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        if (response.status === 200) {
          if (response.data.kyc_level === 5) {
            setData({
              mobverify: response.data.phone_number,
              username: response.data.first_name,
              reason: response.data.last_name,
              tofa: response.data.is_totp_enabled,
              kycLevel: response.data.kyc_level,
              inrdeposit: "/user/inrdeposit",
              inrwithdraw: "/user/inrwithdraw",
              cryptodeposit: "/user/cryptodeposit",
              cryptowithdraw: "/user/cryptowithdraw",
            });
          } else {
            setData({
              mobverify: response.data.phone_number,
              username: response.data.first_name,
              reason: response.data.last_name,
              tofa: response.data.is_totp_enabled,
              kycLevel: response.data.kyc_level,
            });
          }
          setVissible(true);
        }
      };
      fetchData();
    }
  }, [apiurl, token]);
  return (
    <>
      <div className="dashboard">
        <div className="top">Refer to get up to Rs. 50 trading rewards!</div>
        <div className="hero">
          <Grid container spacing={2} className="herocontent">
            <Grid item xs={12} md={6} className="leftside">
              <div className="head">
                {data.kycLevel === 1 && vissible ? (
                  <>
                    <h2>Welcome!!!</h2>
                    <div className="user">
                      <h3>
                        {data.username == null
                          ? "[#NEW USER], to"
                          : data.username}
                      </h3>
                    </div>
                    <h1>SOARXBITS</h1>
                    <div>
                      <h4>Buy & Sell</h4>
                      <h4>Crypto in Smoothen</h4>
                      <h4>Way</h4>
                    </div>
                    <Alert
                      severity="warning"
                      sx={{ marginTop: 3, width: "100%" }}
                    >
                      You must verify mobile number to continue!!!
                    </Alert>
                    <Button
                      href="/user/mobileverify"
                      type="button"
                      variant="outlined"
                      startIcon={<PhoneAndroidIcon />}
                      color="error"
                      sx={{ marginTop: 2 }}
                    >
                      Verify Mobile
                    </Button>
                  </>
                ) : (
                  <>
                    {data.kycLevel === 2 && vissible ? (
                      <>
                        <h2>Welcome!!!</h2>
                        <div className="user">
                          <h3>
                            {data.username == null
                              ? "[#NEW USER], to"
                              : data.username}
                          </h3>
                        </div>
                        <h1>SOARXBITS</h1>
                        <div>
                          <h4>Buy & Sell</h4>
                          <h4>Crypto in Smoothen</h4>
                          <h4>Way</h4>
                        </div>
                        <Alert severity="warning" sx={{ marginTop: 3 }}>
                          You must Update Profile to continue!!
                        </Alert>
                        <Button
                          href="/user/kycdata"
                          type="button"
                          variant="outlined"
                          startIcon={<BadgeIcon />}
                          color="error"
                          sx={{ marginTop: 2 }}
                        >
                          Complete Profile
                        </Button>
                      </>
                    ) : (
                      <>
                        {data.kycLevel === 3 && vissible ? (
                          <>
                            <h2>Welcome!!!</h2>
                            <div className="user">
                              <h3>
                                {data.username == null
                                  ? "[#NEW USER], to"
                                  : data.username + ", to"}
                              </h3>
                            </div>
                            <h1>SOARXBITS</h1>
                            <div>
                              <h4>Buy & Sell</h4>
                              <h4>Crypto in Smoothen</h4>
                              <h4>Way</h4>
                            </div>
                            <Alert severity="warning" sx={{ marginTop: 3 }}>
                              You must upload Documents to continue!!
                            </Alert>
                            <Button
                              href="/user/docupload"
                              type="button"
                              variant="outlined"
                              startIcon={<BadgeIcon />}
                              color="error"
                              sx={{ marginTop: 2 }}
                            >
                              Upload Documents
                            </Button>
                            {data.reason && (
                              <Alert severity="error" sx={{ marginTop: 3 }}>
                                {data.reason}
                              </Alert>
                            )}
                          </>
                        ) : (
                          <>
                            {data.kycLevel === 4 && vissible ? (
                              <>
                                <h2>Welcome!!!</h2>
                                <div className="user">
                                  <h3>
                                    {data.username == null
                                      ? "[#NEW USER], to"
                                      : data.username + ", to"}
                                  </h3>
                                </div>
                                <h1>SOARXBITS</h1>
                                <div>
                                  <h4>Buy & Sell</h4>
                                  <h4>Crypto in Smoothen</h4>
                                  <h4>Way</h4>
                                </div>
                                <Alert severity="warning" sx={{ marginTop: 3 }}>
                                  KYC UNDER VERIFICATION!! [MAY TAKE 24HRS.]
                                </Alert>
                              </>
                            ) : (
                              <>
                                {vissible && (
                                  <>
                                    <h2>Welcome!!!</h2>
                                    <div className="user">
                                      <h3>
                                        {data.username == null
                                          ? "[#NEW USER], to"
                                          : data.username + ", to"}
                                      </h3>
                                    </div>
                                    <h1>SOARXBITS</h1>
                                    <div>
                                      <h4>Buy & Sell</h4>
                                      <h4>Crypto in Smoothen</h4>
                                      <h4>Way</h4>
                                    </div>
                                    <Alert
                                      severity="info"
                                      sx={{ marginTop: 3 }}
                                    >
                                      ENJOY SMOOTH TRADING!!!
                                    </Alert>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={6} className="middle">
              <div className="quickaccess">
                <Link to={data.inrdeposit} className="cont">
                  <CurrencyRupeeIcon />
                  <div>DEPOSIT</div>
                </Link>
                <Link to={data.inrwithdraw} className="cont">
                  <CurrencyRupeeIcon />
                  <div>WITHDRAW</div>
                </Link>
                <Link to={data.cryptodeposit} className="cont">
                  <CurrencyBitcoinIcon />
                  <div>DEPOSIT</div>
                </Link>
                <Link to={data.cryptowithdraw} className="cont">
                  <CurrencyBitcoinIcon />
                  <div>WITHDRAW</div>
                </Link>
              </div>
              <div>
                <Slider {...settings} className="slider">
                  <div>
                    <img src={img1} alt="" />
                  </div>
                  <div>
                    <img src={img2} alt="" />
                  </div>
                  <div>
                    <img src={img3} alt="" />
                  </div>
                  <div>
                    <img src={img4} alt="" />
                  </div>
                </Slider>
              </div>
              <div className="tofa">
                {data.tofa ? (
                  <Alert>Your 2FA is Enabled. Enjoy Login using Auth.</Alert>
                ) : (
                  <Alert severity="error">
                    Please Enable 2FA From Your Profile.
                  </Alert>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={2} className="scrolldata">
          <Grid item xs={12} md={12}>
            <Hotmarket />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
