import React from "react";
import "./buytable.scss";
import { Divider } from "@mui/material";
function financial(x) {
  return Number.parseFloat(x).toFixed(2);
}
const numberToDate = (numberTime) => {
  const date = new Date(numberTime * 1000);
  return date.toTimeString();
};
export const BuyTable = (props) => {
  let buydata = props.data;
  //console.log("Buy Table: ", buydata)
  const renderTrades = () => {
    if (!buydata || !buydata.length || !buydata[0]) return `Loading...`;
    return buydata.map((buydata, index) => (
      <div key={index} className="cont">
        <div className="price">{buydata && buydata.price}</div>
        <div className="amount">{buydata && (buydata.amount * 1).toFixed(3)}</div>
        <div className="total">{buydata && (buydata.total * 1).toFixed(3)}</div>
      </div>
    ));
  };
  return (
    <div className="buydata">
      <Divider />
      <div key={0} className="cont head">
        <div className="price">Price</div>
        <div className="amount">Amount</div>
        <div className="total">Total</div>
      </div>
      <Divider />
      {renderTrades()}
    </div>
  );
};
