// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imgcontainer {
  display: flex;
  width: 100%;
}
.imgcontainer .pan {
  width: 100%;
  height: 220px;
}
.imgcontainer .pan img {
  width: 100%;
}
.imgcontainer .selfy {
  width: 200px;
  height: 220px;
  margin: auto;
}
.imgcontainer .selfy img {
  width: 200px;
  height: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/previewimg/previewimg.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;AACJ;AAAI;EACI,WAAA;EACA,aAAA;AAER;AADQ;EACI,WAAA;AAGZ;AAAI;EACI,YAAA;EACA,aAAA;EACA,YAAA;AAER;AADQ;EACI,YAAA;EACA,YAAA;AAGZ","sourcesContent":[".imgcontainer{\n    display: flex;\n    width: 100%;\n    .pan{\n        width: 100%;\n        height: 220px;\n        img{\n            width: 100%;\n        }\n    }\n    .selfy{\n        width: 200px;\n        height: 220px;\n        margin: auto;\n        img{\n            width: 200px;\n            height: 10px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
