import {
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import Cookies from "js-cookie";
import "./usermarket.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
export const UserMarket = () => {
  const Navigate = useNavigate();
  const [primecoin, setPrimecoin] = useState({});
  const url = process.env.REACT_APP_HOST_ADDR;
  var token = Cookies.get("token");
  useEffect(() => {
    if(token){
      const fetchData = async () => {
        const data = await axios.get(url + "/allmarket", {})
        setPrimecoin(data.data.market);
      }
      fetchData()
    }
  }, []);
  const clickme = (x) => {
    Cookies.set("soarxactivepair", x);
    Navigate("/user/trade");
  };
  if (!primecoin) return false;
  const rows = [];
  for (let i = 0; i < primecoin.length; i++) {
    rows.push(primecoin[i]);
  }
  return (
    <>
      <div className="market">
        <Grid container spacing={2} className="marketdata">
          <Grid item xs={12} md={8} className="leftside">
            <Card sx={{ marginBottom: 3, padding: 0 }}>
              <CardContent sx={{ padding: 1, marginBottom: 0 }}>
                <div className="title">
                  <h3>Markets</h3>
                  <p>Todays Exclusive Crypto Markets</p>
                </div>
              </CardContent>
            </Card>

            {/* <div className="hotmarket"></div> */}
            <div className="marketlist">
              <Card sx={{ backgroundColor: "transparent" }}>
                <CardContent>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Price</TableCell>
                          <TableCell>Vol</TableCell>
                          <TableCell>Tarde</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow
                            key={row.market_id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              className="imgrow"
                            >
                              <img src={row.logo} alt="" />
                              <div className="pair">
                                <div className="base">{row.base}/</div>
                                <div className="quote">{row.quote}</div>
                              </div>
                            </TableCell>
                            <TableCell align="left">
                              {(row.last_price * 1).toFixed(2)}
                            </TableCell>
                            <TableCell align="center">
                              {(row.quote_volume * 1).toFixed(2)}
                            </TableCell>
                            <TableCell align="center">
                              {row.in_trade === true ? (
                                <Button
                                  variant="outlined"
                                  size="small"
                                  onClick={() => clickme(row.market_id)}
                                >
                                  trade
                                </Button>
                              ) : (
                                <Button
                                  disabled
                                  variant="outlined"
                                  size="small"
                                >
                                  trade
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
